// This part of the form will collect the Available data and Due Date of the job
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class DatesForm extends Component {
  
  state = {
    time: new Date(),
    isOpen: false,
    errors: {},
    vehicle: this.props.vehicle
}

  addDays(date, days) {
    let result = moment(date).format("YYYY/MM/DD");
    let results = moment(result).add(days, "days");
    return results;
  }

  render() {

    if (this.props.currentStep !== 5) { // Prop: The current step
      return null;
    }

    let minDate = new moment(new moment().format("YYYY/MM/DD")).toDate();
    minDate = this.addDays(minDate,1).toDate();

    let availableDate = new moment(this.props.availableDate).toDate();
    let availDateDisplay = moment(availableDate).format("MMMM DD, YYYY");
  console.log("Vehicle :", this.props.vehicle);
    let displayVehicle = this.props.vehicle.year + " " + this.props.vehicle.make +" "+ this.props.vehicle.model+" "+ this.props.vehicle.trim;
      // build the display services string for the selected services
    let displayServices = this.props.services[0].service_category + " " + this.props.services[0].description;
    for (var x=1; x < this.props.services.length; x++){
        if (x === this.props.services.length - 1)
            displayServices = displayServices + " & ";
         else
            displayServices = displayServices + ", ";
        displayServices = displayServices + this.props.services[x].service_category + " " + this.props.services[x].description
    }


    return (
      <React.Fragment>
        <form className="bg-request p-5 justify-content-md-center">
          <div className="form-row justify-content-md-center">

            <div className="form-group col-md-2 text-center">
              <label htmlFor="date" className="bold">Select Drop Off Date</label>
            </div>

            <div className="form-group col-md-4 text-center">
              <DatePicker
                name="availableDate"
                selected={availableDate}
                value={availableDate}
                minDate={minDate}
                onChange={this.props.handleAvailableDate}
                excludeDates={this.props.excludeDays}
                dateFormat="MMM do yyyy"
                className="form-control"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
        </div>
        </form>
        <p className="text-center bold">{displayVehicle}</p>
        <p className="text-center bold">{displayServices}</p>
        <p className="text-center bold">for {this.props.customer}</p>
        
        {this.props.notes && <p className="text-center bold">{this.props.notes}</p>}

        <p className="text-center bold">
          Drop off by end of day on {availDateDisplay}
        </p>
      </React.Fragment>
    );

  }

}
