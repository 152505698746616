import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { HTTPService } from "./../../../services/httpService";

import moment from 'moment';
import _ from 'lodash';

const API = new HTTPService();


export class ViewJob extends Component {

    // Fetch Signed URL when the button is clicked
    fetchSignedUrl = async () => {
      const newTab = window.open("", "_blank"); // open the tab
      try {
        const response = await API.get(`signedURL/?requestId=${this.props.request.requestId}`);
        if (response.data.signedUrl) {
          newTab.location.href = response.data.signedUrl; // populate the link
        } else {
          console.error("No signed URL found.");
        }
      } catch (error) {
        console.error("Error fetching signed URL:", error);
        alert("Error accessing contract.");
      }
    };
  

    render(){
      console.log ("Display Job : Data  :", this.props.request, this.props.services);

      let vehicle = this.props.request.vehicleYear + " " +this.props.request.vehicleMake + " " +this.props.request.vehicleModel + " "       
      let totalCost = "$" + (this.props.request.requestTotal + this.props.request.requestTaxes).toFixed(2);
      let totalTax = "$"  + (this.props.request.requestTaxes + 0).toFixed(2);
      let startDate = moment(this.props.request.availableDate).format("ddd, MMM Do YYYY");
      let endDate = moment(this.props.request.dueDate).format("ddd, MMM Do YYYY");
 
      var noteText = "None";
      if (this.props.notes.length > 0) {
        if (this.props.notes[0].note_text.length > 1 )
        noteText = this.props.notes[0].note_text;
      }
         
      const services = this.props.services;
      const columns = [{
        dataField: 'category',
        text: "Category"
        }, {
        dataField: 'desc',
        text: "Service"
        }, {
        dataField: 'amount',
        text: "Amount"
        }, {
        dataField: 'duration',
        text: "Duration"
        }];

        let services_ = [];
        for (let i=0; i < services.length; i++){
          let rowView = {
            serviceId : "",
            category :"",
            desc : "",
            amount : "",
            dur : ""
          };
          rowView.serviceId = services[i].service_id;
          rowView.category = services[i].service_category;
          rowView.desc = services[i].custom_description;
          if(typeof(services[i].service_amount) === "string"){
              rowView.amount = services[i].service_amount;
          } else {
            rowView.amount = "$" + services[i].service_amount.toFixed(2);
          }  
          rowView.duration = services[i].service_duration;
          services_.push(rowView);
        }
    
      return(
            <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Work Detail for {this.props.request.dealerName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
              <div className="col">
            <h6>Email: {this.props.request.email}</h6>
            </div>
            <div className="col">
            <h6>Phone: {this.props.request.phone_number}</h6>
            </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col text-center">
            <h5>{vehicle}</h5>
            </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col">
            <h6>Work Starts : {startDate}</h6>
            </div>
            <div className="col">
            <h6>Work scheduled to complete : {endDate}</h6>
            </div>
            </div>
            <hr></hr>
            {this.props.request.requestType === "installer" && 
            <div className="row">
              <div className="col text-center">
                <h5>Total Cost : {totalCost} including taxes of {totalTax}</h5> 
            </div>
            </div>}
            {this.props.request.requestType === "dealer" && 
            <div className="row">
              <div className="col text-center">
                <h5>Total Cost : {totalCost}</h5> 
            </div>
            </div>}
            {noteText !== "None" &&  <div><hr></hr>
              <div className="row">
              <div className="col">
            <p>Work Notes : {this.props.notes[0].note_text}</p>
            </div>
            </div></div>}
            <hr></hr>
            <h5>Itemized Costs</h5>
            <BootstrapTable 
              keyField='serviceId' 
              data={ services_ } 
              columns={ columns } 
            />
            <div className="row">
              <div className="col">
            <p>Request # {this.props.request.requestId}</p>
            </div>
              <div className="col">
            <p>Invoice # {this.props.request.invoiceId}</p>
            </div>
            <div className="col">
            <p>Current State : {_.capitalize(this.props.request.currentState)}</p>
            </div>
            </div>
            {this.props.request.contract_url && <div className="row">
              <div className="col">
              <button type="button" onClick={this.fetchSignedUrl} className="button btn-secondary m-2 buttons">CUSTOMER CONTRACT</button>          
              </div>
            </div>}
            </Modal.Body>
          </Modal>
        );
    };
};