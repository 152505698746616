import React, { Component } from 'react';

import {Modal, Button, Row, Col, Form} from 'react-bootstrap';

export class AddPO extends Component {


    state = {
      purchaseOrder : this.props.purchaseOrder,
    };

    handleSubmit = (event) => {
      event.preventDefault();
      let purchaseOrder = event.target.PurchaseOrder.value;
      this.setState({ purchaseOrder});
      this.props.onPO(purchaseOrder);
  };

  handleInvoice = (event) => {
    event.preventDefault();
    let purchaseOrder = event.target.PurchaseOrder.value;
    let checkNumber = event.target.CheckNumber.value;
    this.setState({ checkNumber});
  this.props.onInvoice(this.props.requestId, purchaseOrder, checkNumber);
};

    render(){
      let title="Enter the PO #";

      if (this.props.type === "installer"){
        if (this.props.purchaseOrder){
          title = "Enter the check # and close out invoice"
        } else {
          title = "Enter the PO and/or check # and close out invoice"
        }
      }
//      console.log("addPO : props : ", this.props);
        return(
          <div>
            <Modal
            show={this.props.show}
            onHide={this.props.onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col sm={6}>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="PaymentDetail">
                      <Form.Label>PO #</Form.Label>
                        {!this.props.purchaseOrder && <Form.Control
                        type="text"
                        name="PurchaseOrder"
                        required
                        placeholder="This will store with the invoice"
                        />}
                        {this.props.purchaseOrder && <div>{this.props.purchaseOrder}</div>}
                      {this.props.type === "installer" && <div>
                      <Form.Label>Check #</Form.Label>
                        <Form.Control
                        type="text"
                        name="CheckNumber"
                        placeholder="This will store with the payment"
                        />
                        </div>}
                      </Form.Group>
                      <Form.Group>
                        {!this.props.purchaseOrder && <Button variant="primary" type="submit" className="buttons">
                          Add PO
                        </Button>}
                        {this.props.type === "installer" && <Button variant="primary" className="buttons" onClick={() => this.handleInvoice()}>
                          Close Dealer Invoice
                        </Button>}
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          </div>
        );
    };
};