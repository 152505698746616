import React, { Component } from 'react';
import Select from './../../../components/common/select';
import { HTTPService } from "./../../../services/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import moment from 'moment';
import _ from 'lodash';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
//import { sort } from  'react-bootstrap-table2-sort'
import {ViewJob} from '../requestimates/displayJob'; 
import {AddPO} from '../../partial/addPO';

require('dotenv').config();

const API = new HTTPService();

class Financials extends Component {
    state = { 
        typeId : this.props.typeId,
        transactions : [],
        notPaid : [],
        offers : [],
        requests : [],
        estimates : [],
        services : [],
        owed : 0,
        pending : 0,
        earned : 0,
        selectedDate : {},
        today : "",
        thisDay : "",
        thisWeek : "",
        thisMonth : "",
        selectedWeek : "",
        selectedMonth : "",
        startDate : "",
        duration : 0,
        range : "month",
        displayString : "",
        showDetails : false,
        selectedRequestimate : {},
        services : {},
        notes : {},
        showPO : false,
        options : ["All States", "Invoiced", "Paid"],
        selectedOption : "All States",
        types : ["All Types", "Dealer", "Retail"],
        selectedType : "All Types",
        requestId : "",
        poNummber : "",
        invoiceId : ""
    };
  

     async componentDidMount() {
        
     // get all the financials events for the installer/dealer
        
        let financialsRequest = `/installerPayments/${this.state.typeId}`;  
        if (this.props.userType === "dealer"){
            financialsRequest = `/dealerPayments/${this.state.typeId}`;
        }
        const  { data : transactions }  = await API.get(financialsRequest);

        // if dealer list, then convert all "covered" states to "invoiced"

        transactions.forEach((element, index, array) => {
            if (element.payment_state  === "covered") {
                array[index].payment_state = "invoiced";
            }
        });

        this.setState( {transactions} );

        var today = new moment();
        const startDate = moment().startOf('month');
        const endDate   = moment().endOf('month');
        const duration = today.daysInMonth(); 
        var displayString = startDate.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            

//        console.log("Financials : transactions :", this.state.transactions);
        this.setState({ today, startDate, endDate, selectedDate : startDate, displayString, duration });
        };
  
        handleSelect = (event) => {
            const {value} = event.currentTarget;
//            console.log ("Select Event :" , value);
            this.setState( {selectedTime : value });
        };


        handleTypeChange = (event) => {
            const {value} = event.currentTarget;
//            console.log ("Select Event :" , value);
            this.setState( {selectedType : value });
        };

        handleOptionChange = (event) => {
            const {value} = event.currentTarget;
//            console.log ("Select Event :" , value);
            this.setState( {selectedOption : value });
        };

    displayDetails = async (requestId) => {
        console.log("What have got to display", requestId)
        //read the details for the display job
        let dispRequest = `/requestimates/fullrequest/?Id=${requestId}`;        
        const  { data : selectedRequests }  = await API.get(dispRequest);
        let servicesRequest = `/requestservice/${requestId}`;        
        const  { data : services }  = await API.get(servicesRequest);
        let notesRequest = `/requestnotes/${requestId}`;        
        const  { data : notes }  = await API.get(notesRequest);
        this.setState({ selectedRequestimate : selectedRequests[0], services, notes, showDetails : true});

    }

    displayPO = async (invoiceId, requestId) => {
        // find the record in the transaction list that was selected and extract the PO number 
        let poNumberRecord = this.state.transactions.filter(trans => trans.request_id === requestId);
        console.log ("displayPO : parameters :", invoiceId, requestId);
        console.log ("displayPO : found record :", poNumberRecord);
        
        
        this.setState({invoiceId, requestId, poNumber: poNumberRecord[0].payment_order, showPO : true});
    }

    handleAddPO = async (invoiceId, PO) => {
        //  console.log ("This is where we add this PO to the current invoice", PO)
        
          await this.updateInvoiceMetadata(invoiceId, PO);
        
          this.setState({ showPO : false })
          window.location = '/financials';
        }

    handleInvoice = async () => {
            
        const apiEndpoint =  "/invoices/settle/"+this.state.invoiceId;
        const {data:post} = await API.put(apiEndpoint);
        this.setState({ showPO : false })
        window.location = '/financials';
    }
            
    handleOpen = async () => {
        console.log("handleOpen : w-9 :", process.env.W9FILEURL);
        window.open("https://s3.us-east-1.amazonaws.com/jobvious.app/W-9+Jobvious.pdf", "_blank");
    }

    closePO = async () => {
        this.setState( {showPO : false})
    }

    addCustomClose = () => {
        this.setState( {showDetails : false});
      };


    handleDisplayRange = (event) => {
        const {value} = event.currentTarget;
//        console.log ("Direction to travel :" , value);
        console.log ("HandleDisplayRange : selectedDate", this.state.selectedDate.format("MM/DD/YYYY"));
        var newDay = moment(this.state.selectedDate);
        var displayString = "";

// if the range is "day", then we are inc/dec the start date, duration is 1 day
        if (this.state.range === "day") {
            if (value === "today"){
                newDay = new moment();    
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "day");
            }
            if (value === "more"){
                newDay = newDay.add(1, "day");
            }
            var endDate = newDay;
            displayString = newDay.format("ddd, MMM Do YYYY");
        }
        if (this.state.range === "week") {
            if (value === "today"){
                newDay = new moment().startOf("week");    
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "week");
            }
            if (value === "more"){
                newDay = newDay.add(1, "week");
            } 
            var endDate = new moment(newDay).endOf("week");
            displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
//                   console.log("End Date : ", endDate);

        }

        if (this.state.range === "month") {
            if (value === "today"){
                newDay = new moment().startOf("month");
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "month");
            }
            if (value === "more"){
                newDay = newDay.add(1, "month");
            } 
            endDate = new moment(newDay).endOf("month");
            displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
        }
        this.setState( {selectedDate : newDay, startDate:newDay , endDate, displayString});

    };


     handleRange = (event) => {

        console.log ("HandleRange : selectedDate", this.state.selectedDate.format("MM/DD/YYYY"));

                const {value} = event.currentTarget;
        //          console.log ("Button Clicked :" , value);
                var displayString = "";
                var newDay = moment(this.state.selectedDate);
                var endDate = "";
        
                    if (value === "week") {
                        newDay = newDay.startOf('week');
                        endDate = new moment(newDay).endOf('week');
                    }
                    if (value === "month") {
                        newDay = newDay.startOf('month');
                        endDate = new moment(newDay).endOf('month');
                    }
                    if (value === "day") {
                    // set the day to beginning of week
                       endDate = new moment(newDay);
                    }

                    displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
                this.setState({ range : value , selectedDate : newDay, startDate:newDay, endDate, displayString});
        
    };
        

    render() { 
        
        
        const divHeight = {
            background : "#ffffff"
        }
        const divHeight1 = {
            height:"70px",
            background : "#ffffff"
        }
        const reqStyle = { 
            backgroundColor : '#FFFFFF'
        }
        const jobStyle = { 
                backgroundColor : '#98CBE5'
        }
    
        // Build the counts for display based on the selected date
        var workingArray = this.state.transactions;

        // working Array contains all the invoices in the time period
//        console.log ("newFinancials : startDate : ", this.state.startDate);
//        console.log ("newFinancials : endDate : ", this.state.endDate);


        // Jobs
        let jobs = workingArray.filter(item => moment(item.invoice_date)>= this.state.startDate && moment(item.invoice_date) <= this.state.endDate);
        if (this.state.selectedOption !== "All States"){
            jobs = jobs.filter(item => _.capitalize(item.payment_state) === this.state.selectedOption)
        }
        if (this.state.selectedType !== "All Types"){
            if(this.state.selectedType === "Retail"){
                jobs = jobs.filter(item => item.Request_type === "installer")
            } else {
                jobs = jobs.filter(item => item.Request_type === "dealer")

            }
    }

//        console.log("Financials : company : ", this.props.company);
        console.log("Financials : jobs :", jobs);

        let dealerView = false;
        if (this.props.userType === "dealer")
            dealerView = true;


        const columns = [{
            dataField: 'vehicle',
            text: "Vehicle",
            formatter : (cell, row) => (<a href={row.link} onClick={(e) => {e.preventDefault(); this.displayDetails(row.requestId)}}> {cell} </a>)
        },{
            dataField: 'invoiceDate',
            text: "Invoiced"
          },  {
            dataField: 'currentState',
            text: "State",
            formatter: (cell, row) => {
                if (this.props.userType === "dealer") {
                    if (cell === 'Invoiced' && !row.poNumber) {
                        return <a href={row.link} onClick={(e) => {e.preventDefault(); this.displayPO(row.invoiceId, row.requestId)}}>{cell} </a>;
                    } else {
                        return cell;
                    }
                } else {
                    if (cell === "Invoiced" && row.requestType === "Dealer"){
                        return <a href={row.link} onClick={(e) => {e.preventDefault(); this.displayPO(row.invoiceId, row.requestId, row.poNumber)}}>{cell} </a>;
                    } else {
                        return cell;
                    }
                }
              }
          },{
            dataField: 'service',
            text: "Work"
          }, {
            dataField: 'requestType',
            text: "Type"
          }, {
            dataField: 'amount',
            text: "Amount"
          }, {
            dataField: dealerView ? 'poNumber' : 'taxes',
            text: dealerView ? "PO #" : "Tax"
          }, {
            dataField: 'invoiceNumber',
            text: "Number",
            formatter : (cell, row) => (<a href={row.link} target="_blank"> {cell} </a>)
          }];
          // map items from owed into requests
          // for every item in the notPaid array, get the details from the offers array and display

          const rowStyle2 = (row, rowIndex) => {
            // set the style of the row to red if the invoice is overdue
            // set the background to blue if a installer job
            if (row.requestType === "Retail") {
                return jobStyle
            } else {
                    return reqStyle;
                };      
            };


            var displayArray = [];
        
          for (let i=0;  i < jobs.length; i++){
            let displayObject = {
                invoiceDate : "",
                currentState : "",
                link : "",
                requestId : "",
                requestType : "",
                service : "",
                vehicle : "",
                amount : "",
                fee : "",
                invoiceNumber : "",
                invoiceId : "",
                poNumber : ""
            };
//            console.log (`Index found for ${jobs[i].Request_id} is ${found}`);
                displayObject.invoiceDate = moment(jobs[i].invoice_date).format("YYYY-MM-DD");
                displayObject.requestId = jobs[i].Request_id;
                displayObject.requestType = "Retail"
                if (jobs[i].Request_type === "dealer" || jobs[i].Request_type === "Dealer") {
                    displayObject.requestType = "Dealer"
                }
                displayObject.service = jobs[i].Service_Requested;
                displayObject.currentState = _.capitalize(jobs[i].payment_state);
                displayObject.link = jobs[i].hosted_invoice_url;
                displayObject.vehicle = jobs[i].Year + " " + jobs[i].Make + " " + jobs[i].Model ;
                displayObject.amount = jobs[i].Request_Total.toFixed(2);
                displayObject.fee = jobs[i].Request_Fee.toFixed(2);
                displayObject.taxes = jobs[i].request_taxes.toFixed(2);
                displayObject.invoiceNumber = jobs[i].stripe_invoice;
                displayObject.poNumber = jobs[i].payment_order;
                displayObject.invoiceId = jobs[i].invoice_id;
                displayObject.earned = (jobs[i].request_total - jobs[i].request_fee).toFixed(2);
//                console.log("array entry :", i, displayObject);
                displayArray.push(displayObject);
          }
          displayArray.sort((a,b) => ((b.invoiceDate > a.invoiceDate) || (b.invoiceDate === a.invoiceDate && a.currentState > b.currentState)));

        const divStyle = { backgroundColor : "#dde3e9"};
        const requests = displayArray;
          
        return ( 
            <React.Fragment>
            <div className="container-flex m-3">
            <div className="row" style={divStyle}>
                <div className="col text-left w-2">
                <ButtonGroup className="mb-2">
                    <Button variant="outline-primary" onClick={this.handleRange} value="day" active={this.state.range === "day"} >Day</Button>
                    <Button variant="outline-primary" onClick={this.handleRange} value="week" active={this.state.range === "week"} >Week</Button>
                    <Button variant="outline-primary" onClick={this.handleRange} value="month" active={this.state.range === "month"}>Month</Button>
                </ButtonGroup>
                </div>
                <div className="col text-center w-6">
                    <h6>{this.state.displayString}</h6>
                </div>
                <div className="col text-right w-2">
                <ButtonGroup className="mb-2">
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} value="less"><i className="fa fa-caret-left"></i></Button>
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} active={moment(this.state.selectedDate).isSame(this.state.today, 'day')} value="today">Today</Button>
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} value="more"><i className="fa fa-caret-right"></i></Button>
                </ButtonGroup>
                </div>
            </div>
            <div className="row">
            <div className="col-2 text-center">
                <Select
                name="filterOption"
                label=""
                options={this.state.options}
                value={this.state.selectedOption}
                onChange={this.handleOptionChange}
                />
                </div>
                {this.props.userType === "installer" && <div className="col-2 text-center">
                <Select
                name="filterType"
                label=""
                options={this.state.types}
                value={this.state.selectedType}
                onChange={this.handleTypeChange}
                />
                </div>}
                {this.props.userType === "dealer" && 
                <div className="col text-center">
                    <Button variant="primary" className="btn btn-success" onClick={this.handleOpen}>Download W-9</Button>
                </div>}
            </div>

            </div>

                <div className="container-flex m-3 " style={divHeight}>
                <div className="row">
                   <div className="col text-center">
                    <BootstrapTable
                    keyField='requestId'
                    data={requests}
                    columns={columns} 
                    rowStyle={ rowStyle2 }/>
                    </div>
                </div>
            </div>

            <ViewJob
                show={this.state.showDetails}
                onHide={this.addCustomClose}            
                request={this.state.selectedRequestimate}
                services={this.state.services}
                notes={this.state.notes}
            />
            <AddPO
                show={this.state.showPO}
                onClose={this.closePO}
                type={this.props.userType}
                requestId={this.state.requestId}
                invoiceId={this.state.invoiceId}
                purchaseOrder={this.state.poNumber}
                onPO={this.handleAddPO}
                onInvoice={this.handleInvoice}
            />
            
            </React.Fragment>
      
         );
    }
}
 
export default Financials;