import React, { Component } from 'react';
import { HTTPService } from "./../../../services/httpService";
import JOI from 'joi-browser';
import { SelectCustomerJob } from '../../partial/selectCustomerJob';
import "../../../assets/css/SearchResult.css";

import Autocomplete from "react-google-autocomplete";

require('dotenv').config();

const API = new HTTPService();

export default class Customers extends Component {

    state = { 
        data : {phone : "",
    email : "",
    name : "",
    type : "",
    id : "",
    input : "",
    stripe_id : ""},
        errors : {},
        found : " ",
    value : "",
    suggestions : [],
    customerList : [],
    customerJobs : [],
    showJobList : false,
    text : "",
    results : [],
};


async componentDidMount () {            

  // load in all the customers for the installer
    const customerRequest =  "/customerInstall/"+this.props.typeId;
    const { data : customers } =  await API.get(customerRequest);
    customers.forEach(function (element) {
        element.listValue = element.customer_name + " " + element.customer_phone_number;
      });
    this.setState ({ customerList : customers});
    console.log ("componentMount : Customer List ", customers );
};

  // set up the schema to update an entry
     schema = {
        phone: JOI.string().length(10).required().label('phone'),
        email: JOI.string().email().required().label('email'),
        name : JOI.string().required().label('name'),
        address : JOI.optional(),
        type : JOI.optional(),
        id : JOI.optional(),
        stripe_id : JOI.optional(),
    }; 

    // get the full customer details for the selected customer

        async getCustomer(custId) {            

            const customerRequest =  "/customers/"+custId;
            const { data : customer } = await API.get(customerRequest);
            console.log("GetCustomer : customers : ",customer);
            let cust = customer[0];            
            if (customer.length > 0) {
                let data = {
                    name : cust.customer_name,
                    phone : cust.customer_phone_number,
                    email : cust.customer_Email_Address,
                    address :cust.customer_address,
                    type : cust.customer_type,
                    id : cust.customer_id,
                    stripe_id : cust.stripe_customer_id
                }
                this.setState( { found : "Y", data});
// Get the customers previous jobs at the specialist
                const custJobs = "/getcustomerjobs/"+cust.customer_id+"/"+this.props.typeId;
                const { data : customerJobs } = await API.get(custJobs);
//                console.log("Customer Jobs : ", customerJobs);
                this.setState( {customerJobs});

            } else {
                this.setState( { found : "N"});
            }
            // need to handle the case where this isn't available
          };

          // update the record with the updated information
          async updateCustomer(name, email, phone, address) {            
            console.log('Updating Customer Record');
            const customer = {
                customerName : name,
                customerEmailAddress : email,
                customerPhoneNumber : phone,
                customerAddress : address,
                customerType : this.state.data.type,
                customerId : this.state.data.id,
                stripeCustomerId : this.state.data.stripe_id
              };
              console.log('Record :', customer);
          
            const apiEndpoint =  "/customer/" + customer.customerId;
            const {data:customerId} = await API.put(apiEndpoint, customer);
            let data = {
                name : "",
                phone : "",
                email : "",
                type : "",
                id : "",
                stripe_id : ""
            }
            this.setState({data, found : "N", input : ""});
            alert("Customer record updated");
            window.location = "/customers";
        };

        // if cancel clicked, return back to the unselected list
        handleCancel = () => {
            let data = {
                name : "",
                phone : "",
                email : "",
                address : "",
                type : "",
                id : "",
                stripe_id : ""}
              this.setState({data, found : " ", input : ""});
              window.location = "/customers";
          };
    
      // validate the date entered for the customer record
      validate = () => {
        const options = { abortEarly: false };
        const { error } = JOI.validate(this.state.data, this.schema, options);
        console.log("Errors :", error);
        if (!error) return true;
    
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;

        this.setState({ errors });
   
        return false;
      };

      // validate the field entered based on the schema
      validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = JOI.validate(obj, schema);
        return error ? error.details[0].message : null;
      };
      
      handleDataChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
//        console.log ("field validate :", input)
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    
        const data = { ...this.state.data };
        data[input.name] = input.value;
    
        this.setState({ data, errors });
      };

      handleAddress = (place) => {
//        console.log ("Customer Address : ", place.formatted_address);
        this.setState( {address : place.formatted_address })
      }

      setResults = (results) => {
        this.setState( {results});
      }

      // if the search field value changes, return the list of matching customers  
      handleSearchChange = (value) => {
        console.log("handleSearchChange : ", value);
        this.setState( {input : value});
        this.fetchData(value);
      };
      
      fetchData = (value) => {
        console.log("Customers : fetchData :", this.state.customerList);
        console.log("Customers : value : ", value);
      
        const results = this.state.customerList.filter((user) => {
          return (
            value &&
            user &&
            user.listValue &&
            user.listValue.toLowerCase().includes(value.toLowerCase())
          );
        });
        console.log("Customers : results :", results);
        this.setResults(results);
      };

      // close the window of related jobs if open
      handleClose = () => {
        this.setState( {showJobList : false});
      }

      // if the customer is picked from the list, then that's the record to retrieve
      handlePick = (pick) => {
        console.log ("HandlePick : ", pick);
        this.setState( {input : pick.customer_phone_number, id : pick.customer_id});
        this.setResults([]);
        this.doSubmit(pick.customer_phone_number);
      }
      // if there are previous jobs for the customer and the user clicks to show them, open the modal
      handleDisplay = () => {
        this.setState( {showJobList : true});
      }
      // go select the information for the selected customer      
      doSubmit = (id) => {
        // Go and get the customer information from db 
        this.getCustomer(id);
    };
            
    doUpdate = () => {
        // Update the customer information from db 

        if (this.validate()) {
            let newName = this.state.data.name;
            let newEmail = this.state.data.email;
            let newAddress = this.state.data.address;
            let newPhone = this.state.data.phone;
            this.setState({name : newName, email : newEmail, address : newAddress, phone : newPhone});

            var newCustomer = this.updateCustomer(this.state.data.name, this.state.data.email, this.state.data.phone, this.state.address);
        // when the promise is resolved, update the state variables
            newCustomer.then(done => {
                this.setState({found : "Y"});
            })
    } else {
      console.log ("Error validating :" );
    }

    };

    
    render() { 
        const backgroundStyle= {backgroundColor : '#FFD9AC', height : "450px"};
        const blockHeight = {height : "200px"};
        const greyBackround = {backgroundColor : "#FFFFFF", height : "300px"};
        
        return (
            <React.Fragment>
                <h4 className="text-center">Manage your customer information</h4>

                <form className="bg-complete shadow p-5 corners">
                  <div className="form-row justify-content-md-center">
                      <div className="form-group col-md-2 text-center">
                          <label htmlFor="name" className="bold mt-1">Enter Name or Number</label>
                      </div>
                      <div className="search-bar-container">
                          <div>
                              <input
                                    placeholder="Type to search..."
                                    autoFocus
                                    value={this.state.input}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                    onBlur={() => 
                                    setTimeout(() => (
                                        this.setResults([])
                                    ), 100)}
                                />
                            {this.state.results && this.state.results.map((result, i) => 
                              <div key={i} className="search-result" onClick={(e) => this.handlePick(result)}
                              > {result.listValue} </div>
                            )
                            }
                          </div>
                        </div>  
                  </div>
                </form>
                {this.state.found === "Y" && <div className="m-2 shadow" style={greyBackround}>
                <div className="row">
                        <div className="col text-center">
                            <h4>Update the Customer's information</h4>
                        </div>
                    </div>
                <div className="row">
                        <div className="col text-center">
                        <label className="bold mt-1" htmlFor="name">Full Name : </label>    
                        <input 
                            type='text'
                            name='name'
                            value={this.state.data.name}
                            onChange={this.handleDataChange}
                            error={this.state.errors['name']}/>
                            {this.state.errors['name'] && <div className="alert alert-danger">{this.state.errors['name']}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                        <label className="bold mt-1" htmlFor="name">Phone : </label>    
                        <input 
                            type='text'
                            name='phone'
                            value={this.state.data.phone}
                            onChange={this.handleDataChange}
                            error={this.state.errors['phone']}/>
                            {this.state.errors['phone'] && <div className="alert alert-danger">{this.state.errors['phone']}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <label className="bold mt-1" htmlFor="email">Email : </label>    
                            <input 
                                type='text'
                                name='email'
                                value={this.state.data.email}
                                onChange={this.handleDataChange}
                                error={this.state.errors['email']}/>
                                {this.state.errors['email'] && <div className="alert alert-danger">{this.state.errors['email']}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <label className="bold mt-1" htmlFor="email">Address : </label>    
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API}
                                style={{ width: "40%" }}
                                onPlaceSelected={(place) => this.handleAddress(place)}
                                options={{
                                    types: [],
                                    componentRestrictions: { country: "us" },
                                }}
                                defaultValue={this.state.data.address}/>
                        </div>
                    </div>

                    <div className="col text-center">
                    <button type="button" className="btn shadow btn-success m-2 buttons" onClick={this.doUpdate} >Update Customer</button>             
                    <button type="button" className="btn shadow btn-danger m-2 buttons" onClick={this.handleCancel} >Cancel</button>             
                    { this.state.customerJobs.length > 0 && <button type="button" className="btn shadow btn-primary m-2 buttons" onClick={this.handleDisplay} >Show Jobs</button> }            
                    </div>
                </div>}

                <SelectCustomerJob
                    showJobList = {this.state.showJobList}
                    onHide = {this.handleClose}
                    jobs = {this.state.customerJobs}
                    onSelect = {this.handleJobSelect}
                    mode = "view"
                    step = ""
                />

            </React.Fragment>
        );
    }
}
