import React from 'react';
import _ from "lodash";
import moment from 'moment';
import 'moment-timezone';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import DatePicker from 'react-datepicker';
import {CheckOut} from '../../partial/checkOut'
import {AddPO} from '../../partial/addPO'
import Select from '../../common/select';
import "react-datepicker/dist/react-datepicker.css";
import StarRatingComponent from 'react-star-rating-component';
import { convertToUTC, convertFromUTC } from '../../../util/convert_timezone';


const requestimateState = (props) => {

  const { userType, typeId, userAccess, installer, request, services, notes, displayedList, offers, history, invoice, calendarDays, excludeDays, startDate, paymentIntentId, onStartDateChange, 
    template, onTemplateChange, materials, onMaterialsChange, vin, vehicleEntered, vinGood, onVINChange, rowSelected,
    onDelete, onSelectOffer, selectedInstaller, selectedService, onAccept, onAcceptEstimate, onCompleteEstimate, onReject, onAssign, onReceive, onReschedule, onStart, onFinish, onComplete, onSelectService, onSelectInstaller, onEdit,
    reviewScore, onReviewScoreChange, onSettle, onResend, onFinalize, onReview, selectedPay, onPaymentChange, onPayment, onDeliver, onPageChange, activePage, showSwipe, closeSwipe, showPO, closePO, onAddPO, onPO, loading } = props;

    function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

var payList = ["Check/Cash", "Pay Online", "Swipe Card"];

if (installer.stripe_location === "") 
payList = ["Check/Cash", "Pay Online", "Settle"];

  function stripeOutside(url) {
    console.log(invoice);
    window.open(url);
  };

  // if nothing selected, selected item isn't in the displayed list, we are done
  if (isEmpty(props.request)) {
    return null;
  } else {

    let check = [];
    check = displayedList.filter(item => (item.requestId === request.requestId));

    if (check.length === 0) {
      return null;
    } else {

    // if something selected, but selected item isn't in the displayed list, we are done
   
   
  /* This section determines the offers to show to the user. */
  /* if the user is the installer, they only see their own offer */
  /* if the user is the dealer, they see all the answered offer */

  let displayOffers = offers;
  let selectedOffer = [];


  var noteText = "None";
  if (notes.length > 0) {
    if (notes[0].note_text.length > 1 )
    noteText = notes[0].note_text;
  }

  
  for (let index = 0; index < displayOffers.length; index++) {
    let conv = convertToUTC(displayOffers[index].startDate).format("ddd, MMM Do, YYYY");
    displayOffers[index].startDateString = conv;
    displayOffers[index].installerName = offers[index].installerName ;
    // console.log ("Date conv :", conv);
  }
  
  
  if (props.userType === "installer") {
    selectedOffer = offers.filter(offer => (offer.installerId === parseInt(typeId)))
  }
  if (props.userType === "dealer") {
    displayOffers = offers.filter(offer => (offer.currentStatus === "accepted"))
  }

  // This section sets up the initial values for dates & Services
  
      const thisService = activePage - 1;

      let dueDate = new Date(request.dueDate);
      if (userType === "installer" && request.requestType === "installer") {
        // add 30 days to proposed due date 
        let calcDate = new moment(request.dueDate).add(30, "days");
        dueDate = new Date(calcDate);
      }
  
      const minDate = new Date(request.availableDate);
      
      let sDate = new Date(startDate);
      if (sDate < minDate) {
        sDate = minDate
      };
      let jobDuration = parseInt(request.requestDuration);
      let latestStartDate = new moment(request.dueDate).subtract(jobDuration, "days");
      let maxDate = new Date(latestStartDate);
      
      console.log("User Type : ", props.userType);
      console.log("Request Type : ", request.requestType);
      console.log("Current State : ", request.currentState);
      console.log("Assigned To : ", request.assignedTo);
//       console.log("Calendar of events ", calendarDays);

// This needs to be sorted by service ID here to make sure that arrays match

        var thisJobServices = Array.from(calendarDays.filter(day => day.requestId === request.requestId));
//        console.log("This job services is :", thisJobServices); 
        var thisJob = _.orderBy(thisJobServices,'serviceId','asc')
        var serviceCount = thisJob.length;

 //      console.log("This job is :", thisJob);
 //       console.log("Selected Installer :", installer);
 //       console.log("Exclude Days :", excludeDays);
 //       console.log("Work to do :", (installer.capacity - thisJob[0].duration))

    // set up dates to exclude based on the calendar
    let exclude = [];
    if (thisJob.length > 0) {
    for (let b=0; b< excludeDays.length; b++){
      // this should be the capacity of the installer vs the size of job
      if (excludeDays[b].y > (installer.capacity - thisJob[0].duration)){
//        console.log ("Exclude :", new Date(moment(excludeDays[b].exDate).format("MMMM D, YYYY")));
        exclude.push(new Date(moment(excludeDays[b].exDate).format("MMMM D, YYYY")));
      }
    }
  }
//    console.log ("Exclude these :", exclude)
    let thisJobDates = [];
    for (let c=0; c< thisJob.length; c++){
        thisJobDates.push(new Date(moment(thisJob[c].workDate).format("MMMM D, YYYY")))
    }
    
    
    let workingInvoice = JSON.parse(JSON.stringify(invoice));
    let findMeta = Object.entries(workingInvoice);
    let findValue = findMeta[48];


//    console.log("RequestimateState : Payment Intent ", paymentIntentId);
//    console.log("RequestimateState : Working Invoice ", workingInvoice);
//    console.log("RequestimateState : Keys ", findMeta);
//    console.log("RequestimateState : Found Key ", findValue);
//    console.log("RequestimateState : Invoice Metadata ", metaData);
//************************************************************************
//***************************  Dealer Pages ******************************
//************************************************************************

// **********************************
// New Request display for Dealer user
// **********************************
if (request.currentState === "new" && props.userType === "dealer") 
{
  const columns = [{
    dataField: 'service_category',
    text: "Category"
}, {
    dataField: 'description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = Array.from(services);
//_.orderBy(services_,['workDate'],['asc']);
//console.log ("RequestimateState : services :", services);
/* for (let i=0; i < services_.length; i++) {
  services_[i].service_amount = "$" + services[i].service_amount.toFixed(2);
} 
*/ 

//console.log ("request state , showSwipe", showSwipe);

return (
    <React.Fragment>
        <div className="card text-center">
            <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='service_Id'
                  data={services_}
                  columns={columns} />
              </div>
              <div className="text-center">
              {userAccess !=="ASSIST" && <button type="button" onClick={() => onEdit(request)} className="button btn-secondary m-2 buttons">EDIT</button>}
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>}
              </div>
            </div>
          </div>

        </React.Fragment>
      );
};

      //*********************************
      // For Answered request for Dealer 
      //*********************************

      if (request.currentState === "answered" && props.userType === "dealer") 
      {
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          bgColor : 'grey',
          onClick: (e, row, rowIndex) => {
            console.log ("State : RowEvents : Selected Row : ", row);
              onSelectInstaller(row);
            }        }
      
        const columns = [{
          dataField: 'installerName',
          text:"Installer"},{
          dataField: 'startDateString',
          text:"Date to Start"}
        ];
        const rowEvents = {
          onClick: (e, row, rowIndex) => {
          console.log ("State : RowEvents : Selected Row : ", row);
            onSelectInstaller(row);
          }};    
          
        return (
        
        <React.Fragment>
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">Approve the work for the specialist to start</h5>
              <div className="table-responsive">
                <BootstrapTable
                  keyField='installerId'
                  data={displayOffers}
                  columns={columns}
                  selectRow={selectRow}
                  rowEvents={rowEvents} /> 
              </div>

              <div className="text-center">
                {userAccess !=="ASSIST" && <button type="button" disabled={!selectedInstaller} onClick={() => onAssign(request, selectedInstaller)} className="btn btn-success m-2 buttons">ASSIGN</button>}
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>}
              </div>
            </div>
          </div>
        </React.Fragment>
        )
        };

// **********************************
// For Scheduled job for Dealer
// for Received state for Dealer 
// For In progress job for Dealer
// For finsihed job for Dealer
// Show the current state of services in the job
// **********************************
if ((request.currentState === "scheduled" || request.currentState === "started" || request.currentState === "received" || 
request.currentState === "finished") && props.userType === "dealer") {
  // set up the table to display the services and their state
const columns = [{
  dataField: 'service_category',
  text: "Category"
}, {
  dataField: 'description',
  text: "Service"
}, {
  dataField: 'Status',
  text: "Status"
}];
let services_ = [];
  
// set up the array for display of history
// for each service, find the latest entry in the history array 

for (let index=0; index < services.length; index++){
  let aService = {
    service_id : 0,
    service_category : "",
    description : "",
    Status : "Scheduled"
  }
  aService.service_id = services[index].service_id;
  aService.service_category = services[index].service_category;
  aService.description = services[index].description;
  let serviceHistory = history.filter(item => (item.requestService === aService.service_id));
  // console.log("service history :", serviceHistory)
  if (serviceHistory.length > 0){
    switch (serviceHistory[serviceHistory.length - 1].requestStatus) {
      case "start" : aService.Status = "In Progress"; break;
      case "finish" : aService.Status = "Completed"; break;
      default : aService.Status = "Sched"; break;
    };
  }
  // console.log("aService", aService)
  services_.push(aService);
}

return (
        <React.Fragment>
          <div className="card text-center">

            <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='service_id'
                  data={services_}
                  columns={columns} />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
};

// **********************************
// For finished job for Dealer
// **********************************
if (request.currentState === "collected" && props.userType === "dealer") return (
  <React.Fragment>
    <div className="card text-center">
      <div className="card-body">
        <h5 className="card-title">Rate the installer work</h5>
        <div className="col-lg-12">
          <StarRatingComponent
            name="rate1"
            starCount={5}
            value={reviewScore}
            onStarClick={onReviewScoreChange}
          />
        </div>
        {userAccess !=="ASSIST" && <button type="button" onClick={() => onReview(request)} disabled={!reviewScore} className="btn btn-success m-2 buttons">REVIEW</button>}
      </div>
    </div>
  </React.Fragment>
);




//************************************************************************
//***************************  Installer Pages ***************************
//************************************************************************

// ******************************************
// New Request display for Installer Estimate
// ******************************************
if (request.currentState === "new" && props.userType === "installer" && (request.requestType === "installer" || (request.requestType === "dealer" && request.assignedTo === props.typeId))) 
{
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    bgColor : 'grey',
    onClick: (e, row, rowIndex) => {
      onSelectOffer(row);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      onSelectOffer(row);
    }};

  const columns = [{
    dataField: 'service_category',
    text: "Category"
}, {
    dataField: 'description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = Array.from(services);
for (let i=0; i < services_.length; i++) {
//  console.log("Display this service amount : ", services[i].service_amount);

  let amount = services[i].service_amount - services_[i].service_discount;
  services_[i].service_amount = "$" + amount.toFixed(2);
}  
// console.log("Start Date :", startDate);
/*                <div className='col-3'>
            {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-success m-2 buttons"> + </button>}
            {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons"> - </button>}
            </div>
*/

return (
    <React.Fragment>
        <div className="card text-center">
            <div className="mt-4 mb-4">
              <div className='row'>
            <div className='col'>
            <form className="form-row justify-content-md-center">
                <div className="form-group col-lg-2 text-center">
                  <label className="bold">Sched Start</label>
                </div>
                <div className="form-group col-lg-4">
                  <DatePicker
                    name="startDate"
                    label="Pick Start Date"
                    value={sDate}
                    selected={sDate}
                    minDate={minDate}
                    excludeDates={exclude}
                    highlightDates={thisJobDates}                                  
                    onSelect={onStartDateChange}/>
                </div>
                </form>
                </div>
              <div className='col-3'>
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons">DELETE</button>}
              </div>
              </div>
              </div>
              <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='service_id'
                  data={services_}
                  columns={columns}
                  selectRow={selectRow} 
                  rowEvents={ rowEvents } />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-8'>
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onAcceptEstimate(request, startDate)} className="btn btn-success m-2 buttons">BOOK JOB</button>}
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onEdit(request)} className="button btn-secondary m-2 buttons">EDIT</button>}
              </div>
              <div className='col-4'>
                {userAccess !=="ASSIST" && <button type="button" onClick={() => onCompleteEstimate(request, startDate)} className="btn btn-primary m-2 buttons">JOB COMPLETED</button>}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
};

// ***********************************************************
// New request display for Installer user for a dealer request 
// ***********************************************************
if (request.currentState === "new" && props.userType === "installer" && request.requestType === "dealer" && request.assignedTo === 0) 
{
  const columns = [{
    dataField: 'service_category',
    text: "Category"
}, {
    dataField: 'description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = Array.from(services);
//console.log("Services for the selected request :", services_);
for (let i=0; i < services_.length; i++) {
//  console.log("Display this service amount : ", services[i].service_amount);
  if (typeof(services[i].service_amount) !=="string"){
    services_[i].service_amount = services[i].service_amount.toFixed(2);
  }
}  

        return(
          <React.Fragment>
        <div className="card text-center">
          <div className="card-body">
          <div className="table-responsive">
                <BootstrapTable
                  keyField='service_Id'
                  data={services_}
                  columns={columns} />
              </div>
            <div className="mt-4 mb-4">
              <form className="form-row justify-content-md-center">
                <div className="form-group col-lg-2 text-center">
                  <label className="bold">Pick Start Date</label>
                </div>
                <div className="form-group col-lg-4">
                  <DatePicker
                    name="startDate"
                    label="Pick Start Date"
                    value={sDate}
                    selected={sDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={exclude}
                    highlightDates={thisJobDates}
                    onSelect={onStartDateChange}
                  />
                </div>
              </form>
            </div>
            {userAccess !=="ASSIST" && <button type="button" onClick={() => onAccept(request, startDate)} className="btn btn-success m-2 buttons">ACCEPT</button>}
            {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons">REJECT</button>}
          </div>
        </div>
        </React.Fragment>
        )
      };
// ***********************************************
// Installer receiving car from dealer/customer
// ***********************************************
if ( request.currentState === "scheduled" 
    && props.userType === "installer") {

return (
    <React.Fragment>
          <div className="card text-center">
    
            <div className="mt-4 mb-4">
              <h6>Scheduled drop off date : {moment(request.availableDate).format("MM/DD/YYYY")}</h6>
            { request.vin.substring(0,2) !== "NV" ? <div>
                    <h6>VIN : {request.vin}</h6>
                </div>
                : <div>
                  <form>
                  <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label className="bold">Enter VIN #</label>
                    </div>
                    <div className="form-group col-lg-4">
                    <input 
                        name="vin"
                        id={vin} 
                        value={vin}
                        onChange={onVINChange}
                        />
                      </div>
                    </div>
                </form>
                </div>}
              {vinGood && request.vin.substring(0,2) === "NV" && <div>
                  <p>{vehicleEntered.ModelYear} {vehicleEntered.Make} {vehicleEntered.Model} </p>
              </div>}
              {userAccess !=="ASSIST" && <button type="button" onClick={() => onReceive(request, vin)} className="button btn-success m-2 buttons" disabled={!vinGood} >CHECK IN</button>}
              {userAccess !=="ASSIST" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">REJECT</button>}
              {userAccess !=="ASSIST" && <button type="button" onClick={() => onReschedule(request)} className="button btn-success m-2 buttons">RESCHEDULE</button>}
              {userAccess !=="ASSIST" && <button type="button" onClick={() => onEdit(request)} className="button btn-secondary m-2 buttons">EDIT</button>}
          </div>
        </div>
    </React.Fragment>
  )};

// ***********************************************
// 3 state pages for a Scheduled job for Installer
// ***********************************************
// *******************************
// Service not started
// *******************************


if ( (request.currentState === "received" || request.currentState === "started" )
  && props.userType === "installer" ) {

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      style : {bgColor : 'grey'},
      onSelect: (e, row, rowIndex) => {
        onSelectService(rowIndex);
      }
    };
  
/*    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        onSelectService(rowIndex);
      }};
*/  

      let columns = [{
        dataField: 'service_category',
        text: "Category"
    }, {
        dataField: 'description',
        text: "Service"
    }, {
        dataField: 'service_amount',
        text: "Amount"
      }, {
        dataField: 'service_duration',
        text: "Duration"
      }, {
        dataField: 'service_state',
        text: "State"
/*    }, {
    dataField: 'data_collect',
    text: "Data" */
}];
    console.log("What do I have before converting :", services)
    let services_ = Array.from(services);
    for (let i=0; i < services_.length; i++) {
      console.log("Display this service amount : ", services[i].service_amount);
      let amount = services[i].service_amount - services_[i].service_discount;
      services_[i].service_amount = amount.toFixed(2);
    }  
    console.log("Services :", services_);
    console.log("This Job : ", thisJob);
    console.log("Selected Service : ", selectedService);

/*                <div className='col-3'>
                    {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-success m-2 buttons"> + </button>}
                    {userAccess !=="ASSIST" && <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons"> - </button>}
                </div>
                <div className='col-3'>
                {request.requestType=== "installer" && {userAccess !=="ASSIST" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>}}
                </div>

                */
        return (
          <React.Fragment>
            <div className="card text-center">
              <div className="card-body">
              <div className='row text-center'>
                <div className='col text-center'>
                    <h5 className="card-title">VIN : {request.vin}</h5>
                </div>
                </div>
                <div className="mt-4 mb-4">
                <div className="table-responsive">
                <BootstrapTable
                  keyField='service_id'
                  data={services_}
                  columns={columns}
                  selectRow={selectRow} 
                />
              </div>
                <form>
                <div className="form-row justify-content-center">
                  <div className="form-group col-lg-12">
                  {userAccess !=="ASSIST" && <button type="button" disabled={!rowSelected || (rowSelected && services_[selectedService].service_state !== "new")} onClick={() => onStart(request, thisJob, thisJob[selectedService].serviceId)} className="btn btn-success m-2 buttons">
                      START SERVICE
                    </button>}
                    {userAccess !=="ASSIST" && <button type="button" disabled={!rowSelected || (rowSelected && services_[selectedService].service_state !== "started")} onClick={() => onFinish(request, thisJob, thisJob[selectedService].serviceId)} className="btn btn-primary m-2 buttons">
                      FINISH SERVICE
                    </button>}
                    {userAccess !=="ASSIST" && <button type="button" onClick={() => onComplete(request, thisJob)} className="btn btn-primary m-2 buttons">
                      COMPLETE JOB
                    </button>}
                    {request.requestType=== "installer" && userAccess !=="ASSIST" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE JOB</button>}
                    { noteText !== "None" && 
                    <button type="button" onClick={() => alert(noteText)} className="button btn-info m-2 buttons">NOTES</button>}
                    {userAccess !=="ASSIST" && <button type="button" onClick={() => onEdit(request)} className="button btn-secondary m-2 buttons">EDIT</button>}

                  </div>
                </div>
                </form>
              </div>
            </div>
            </div>
          </React.Fragment>
        );
    }

// *******************************************
// Customer/Dealer picks up car from Installer
// *******************************************
if (request.currentState === "finished" && props.userType === "installer") 

  var PO = "Not Yet"
  if (Object.keys(workingInvoice.metadata).length === 3){
    if (workingInvoice.metadata.PO > 0)
    PO = Object.values(workingInvoice.metadata["PO"]);
  }

return (
  <React.Fragment>
  <div className="row">
      <div className="col text-center">{invoice.paid ? (<h6>Customer has fully paid</h6>) : (<h6>The customer invoice has been created</h6>) }</div>
    </div>
    <div className="row">
      <div className="col text-center">
            VIN : {request.vin.substring(0,2) === "NV" ? (<b>Not Collected</b>) : (request.vin)}
      </div>
      </div>
      <div className="row">
      <div className="col text-center">
            Invoiced : ${invoice.amount_due/100}
      </div>
      </div>
      {request.requestType === "dealer" && !invoice.paid && <div className="row">
      <div className="col text-center">
            PO Received : {PO}
      </div>
      </div>}
      <div className="row">
      <div className="col text-center">
            Remaining : ${!invoice.paid ? invoice.amount_remaining/100 : 0}
      </div>
      </div>
    {((invoice.paid && request.requestType === "installer") ) && 
    <div className="row">
      <div className="col text-center">
      {userAccess !=="ASSIST" && <button type="button" onClick={() => onDeliver(request)} className="button btn-success m-2 buttons">DELIVER</button>}
      {userAccess !=="ASSIST" && <button type="button" onClick={() => onResend(invoice.id)} className="button btn-success m-2 buttons">SEND INVOICE</button>}
      </div>
    </div>}
    {(request.requestType === "dealer") && 
    <div className="row">
      <div className="col text-center">
      {userAccess !=="ASSIST" && <button type="button" onClick={() => onDeliver(request)} className="button btn-success m-2 buttons">DELIVER</button>}
      {userAccess !=="ASSIST" && <button type="button" onClick={() => onResend(invoice.id)} className="button btn-success m-2 buttons">SEND INVOICE</button>}
      {userAccess !=="ASSIST" && PO === "Not Yet" && <button type="button" onClick={() => onAddPO()} className="button btn-success m-2 buttons">ADD PO</button>}
      </div>
    </div>}
    {(invoice.status === "open" && request.requestType === "installer") &&
        <div>
          <div className="row text-center">
          <div className="col-4"/>
          <div className="col-4 text-center">
          <Select 
            name="paymethod"
            label=" "
            options={payList}
            value={selectedPay}
            onChange={onPaymentChange}
            />
          </div>
        </div>
        <div className="row">
         <div className="col text-center">
               {userAccess !=="ASSIST" && <button className="button btn-success m-2 buttons" onClick={onPayment}> PROCESS PAYMENT </button>}
               {userAccess !=="ASSIST" && <button onClick={() => onResend(invoice.id)} className="button btn-success m-2 buttons">SEND INVOICE</button>}
               {userAccess !=="ASSIST" && <button onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE FULL JOB</button>}
            </div>
        </div>
        </div>}
        {(invoice.status === "draft" && request.requestType === "installer") &&
        <div>
        <div className="row">
         <div className="col text-center">
               {userAccess !=="ASSIST" && <button onClick={() => onFinalize(invoice.id)} className="button btn-success m-2 buttons">SEND INVOICE</button>}
               {userAccess !=="ASSIST" && <button onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE FULL JOB</button>}
            </div>
        </div>
        </div>}
        {selectedPay === "Swipe Card" && showSwipe && <CheckOut
                        show={showSwipe}
                        onClose={closeSwipe}
                        invoice={invoice}
                        request={request}
                        payment={paymentIntentId}
                        installer={installer}
                        onComplete={onSettle}
          />}
        {showPO && <AddPO
                show={showPO}
                onClose={closePO}
                type={"installer"}
                requestId={request.requestId}
                invoiceId={invoice.id}
                poNumber={PO}
                onPO={onPO}
                onInvoice={onSettle}
          />}
    </React.Fragment>
  );
}
}
}

export default requestimateState;
