import React from 'react';
import dateDisplay from './../../common/dateDisplay';
import { convertFromUTC } from '../../../util/convert_timezone';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import {ViewJob} from './displayJob'; 
import { Component } from 'react';

require('dotenv').config();


class Requestimate extends Component {

	state = {
		showDetails : false
	}

	isEmpty = (obj) => {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

    displayDetails = () => {
        this.setState( {showDetails : true})
    }

    addCustomClose = () => {
        this.setState( {showDetails : false});
      };

	render() {
	if (this.props.userType === "dealer") {
		if (this.props.mode === "jobs"){
			ReactGA.send({ hitType: "pageview", page: "/dealerJobDetail" })
		} else {
			ReactGA.send({ hitType: "pageview", page: "/dealerRequestDetail" })
		}
	};
	if (this.props.userType === "installer") {
		if (this.props.mode === "jobs"){
			ReactGA.send({ hitType: "pageview", page: "/installerJobDetail" })
		} else {
			ReactGA.send({ hitType: "pageview", page: "/installerRequestDetail" })
		}
	};
	// const message = "To view more detail of a " + this.props.mode.slice(0, -1) + ", select one from the list"
	// if nothing selected, selected item isn't in the displayed list, we are done
	if (this.isEmpty(this.props.selectedRequestimate)) {
		return (<h5 className="text-center p-3"><span></span></h5>)
	} else {
		// if something selected, but selected item isn't in the displayed list, we are done
		let check = [];
		check = this.props.displayedList.filter(item => (item.requestId === this.props.selectedRequestimate.requestId));
		if (check.length === 0) {
			return (<h5 className="text-center p-3"><span></span></h5>)
		} else {
			// otherwise display the request
			const divStyle = {
				backgroundColor: '#FFD9AC'
			}
			if (this.props.mode === "jobs") {
				divStyle.backgroundColor = "#98CBE5";
			};

			const request = this.props.selectedRequestimate;
			const installer = this.props.installer;
//			console.log("This is the installer record ;", installer);
//			console.log("This is the request record ;", request);
			
			var displayTotal = request.requestTotal + request.requestTaxes;

			// Set up the map components 
			var displayLat = request.latitude;
			var displayLong = request.longitude;
			var displayName = request.dealerName;
			if (this.props.userType === "dealer" && request.assignedTo) {
				displayName = installer.Installer_Name;
				displayLat = installer.latitude;
				displayLong = installer.longitude;
			};
			var custImage = "https://maps.googleapis.com/maps/api/staticmap?size=180x150&zoom=15&markers=color:red%7C"+displayLat+","+displayLong+"&key="+process.env.REACT_APP_GOOGLE_API;
			var custDest = "https://www.google.com/maps/search/?api=1&query="+displayLat+","+displayLong;
			var vehicleImage = require('../../../assets/LogoTest.png');
			if (!request.longitude) {
				custImage = require('../../../assets/LogoTest.png');
			}
//			console.log ("Maps information : ", displayLat, displayLong, displayName);
			console.log(" requestimate : dates :", request.availableDate, request.dueDate,this.props.offers[0].endDate);
		

			return (
				<React.Fragment>
					<div className="card-group corners">
						<div className="card m-2 shadow corners">
							<img className="card-img" src={vehicleImage} alt=''/>
    		                	<div className="card-image-overlay">
            		            	<p className="text-center">{request.vehicleYear} {_.upperCase(request.vehicleMake)} {_.upperCase(request.vehicleModel)}</p>
                    		    	<p className="text-center">{_.upperCase(request.vehicleTrim)}</p>
                    			</div>
						</div>
						<div className="card m-2 shadow corners">
							<div className="card-body">
                        	<div className="row">
                            	<div className="col text-center">
                                	{dateDisplay(convertFromUTC(request.availableDate).format("MMMM D, YYYY"))}
                            	</div>
                            	<div className="col text-center">
                                	{request.requestType === "installer"
                                	? dateDisplay(convertFromUTC(this.props.offers[0].endDate).format("MMMM D, YYYY"))
                                	: dateDisplay(convertFromUTC(request.dueDate).format("MMMM D, YYYY"))}
                            	</div>
                        	</div>
                        	<div className="row u-2">
                            	<div className="col text-center">
                                	{request.serviceRequested}
                            	</div>
                        	</div>
                        	<div className="row">
                            	<div className="col text-center">
                                	<h4>{"$" + displayTotal.toFixed(2)}</h4>
                            	</div>
                        	</div>
                        	<div className="row">
                            	<div className="col text-center">
								{request.requestType === "installer" ? "incl. Sales Tax" : ""}
                            	</div>
                         	</div>
                         	<div className="row text-center">
                            	<div className="col">
                            	<button 
                                	className="btn-sm btn-success m-2 buttons" 
                                	onClick={this.displayDetails}>
                                	Details
                            	</button>
                            	</div>
                         	</div>
                    	</div>
						</div>
						<div className="card m-2 shadow corners"> 
						{request.longitude
                                	? <a href={custDest} target="_blank"><img className="card-img" src={custImage} alt='' onClick={()=> custDest} /></a>
                                	: <img className="card-img" src={custImage} alt='' />
								}
                        <div className="card-image-overlay corners">
						<p className="card-text text-center">{displayName}<br/>
                            {request.email}<br/>
                            {request.phone_number} </p>
                        </div>
                    </div>
					<ViewJob
                show={this.state.showDetails}
                onHide={this.addCustomClose}            
                request={this.props.selectedRequestimate}
                services={this.props.services}
				notes={this.props.notes}
            />
					</div>
				</React.Fragment>
			);
		}
	}
}
}

export default Requestimate;
