import React, { Component } from 'react';
import Select from './common/select2';
import {AddCustomCut} from './partial/addCustomCut'
import {AddDiscount} from './partial/addDiscount';
import { SelectCustomerJob } from './partial/selectCustomerJob';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { HTTPService } from "./../services/httpService";

import _ from 'lodash';

const API = new HTTPService()

export default class ServiceForm extends Component {

    state = {services : [] ,
        errors : {},
        selectedServices : [],
        categories : [],
        selectedService : {},
        selectedCategory : {},
        selectedDiscount : this.props.discount,
        displayServices : [],
        totalCost : parseFloat(this.props.total),
        totalDiscounted : 0,
        discount : this.props.discount,
        totalDuration : 0,
        selectedServicePrice : "",
        message : "Requested Work",
        showCustom : false,
        showDisc : false,
        estimateDesc : "",
        estimateCost : 0,
        estimateDuration : 0,
        custJobs : []

    }

    // this will return the services offered
    async componentDidUpdate(prevProps) {
        console.log("prevProps vehicle :", prevProps.vehicle);
        console.log("current Props vehicle ;", this.props.vehicle);

        if ((this.props.vehicle !== prevProps.vehicle) || (this.props.customerType !== prevProps.customerType)){

        var servicesRequest =  this.props.typeId + "/" + this.props.vehicle.size+ "/" + _.upperCase(this.props.customerType);

        // read the correct services based on the type of work being entered

        console.log("ComponentDidUpdate : serviceRequest :", servicesRequest);

        if (this.props.userType === "dealer"){
            servicesRequest =  this.props.specialist + "/" + this.props.vehicle.size+ "/WHOLE";
          };
//          console.log("ComponentDidUpdate : servicesRequest :", servicesRequest);

        // Fetch the services
        const { data: allServices } = await API.get(`/services/${servicesRequest}`);

        let categories = [...new Set(allServices.map(x => x.service_category))];
        let services = [...new Set(allServices)];

        console.log ("ServiceForm : Services :", allServices);

        // filter custom services if dealer 
        if (this.props.userType === "dealer"){
            services = services.filter((service) => service.description !== "Custom");
        };

        this.setState({ services, categories, selectedService: '' });

        // also go and see if there are any previous jobs for that vin number for this installer
        // or get the job for the request provided 

        if (this.props.vehicle && this.props.userType === "installer" ) {
        let jobsQuery = "";    
        if (this.props.currentStep === 99){
            jobsQuery =  "/requestimates/requestbyID/?Id="+this.props.notes.Request_id;
        } else {
            jobsQuery =  "/requestimatesVIN/"+this.props.typeId + "/" + this.props.vehicle.vin;
        }
        // Fetch the jobs

        const { data: jobs } = await API.get(jobsQuery);

        this.setState({ custJobs : jobs, selectedJob: '' });

//        console.log ("Service Form : Selected jobs for VIN : ", jobs.length);

        if (jobs.length > 0 ) 
          this.setState({ showJobList : true});

        }
        }
    };

    
    addService = () => {
        //add the service to the list
        let selectedServices = Array.from(this.state.selectedServices);

        // Is the service already in the list, then ignore the action 

        this.setState( {message : "NOTE : Price may vary based on selected model", showCustom : false} );

        console.log ("Adding Service : What does Service contain : ", this.state.selectedService);
        console.log ("Adding Service : What does TotalCost contain : ", this.state.totalCost);
        console.log ("Adding Service : What does discount contain : ", this.state.discount);

        let totalCost = this.state.totalCost;
        let totalDuration = this.state.totalDuration;
        let discount = this.state.discount;
        let totalDiscounted = this.state.totalDiscounted;
        let goodToGo = 0;

        // if building from a job that's already created, just populate the values and update the totals for 
        // each service

        if (this.state.selectedService.request_id) {
            let serviceSelected = {
                description : this.state.selectedService.custom_description,
                service_category : this.state.selectedService.service_category,
                service_cost : this.state.selectedService.service_cost,
                service_discount : this.state.selectedService.service_discount,
                service_amount : this.state.selectedService.service_amount,
                service_id : this.state.selectedService.service_id,
                data_collect : this.state.selectedService.data_collect,
                duration_days : this.state.selectedService.duration_days,
                installer_category : this.state.selectedService.installer_category,
                installer_id : this.state.selectedService.installer_id,
                market_size : this.state.selectedService.market_size,
                overrides : this.state.selectedService.overrides,
                vehicle_size : this.state.selectedService.vehicle_size
            };               
            totalCost = totalCost + serviceSelected.service_amount;
            discount = discount + serviceSelected.service_discount;
            totalDiscounted = totalCost - discount;    
            totalDuration = totalDuration + this.state.selectedService.duration_days;
            selectedServices.push(serviceSelected);
            console.log ("Adding Service : What does TotalCost contain : ", totalCost);
            console.log ("Adding Service : What does discount contain : ", totalDiscounted);    
            this.setState( {selectedServices, totalCost, totalDiscounted, totalDuration});
            this.props.handleServices(selectedServices, totalCost, this.state.selectedDiscount);
        } else {

        if (!selectedServices.find(svc => (svc.service_id === this.state.selectedService.service_id)))
        {
            // Is the service an override for one that's already in the list, if so, remove the other one and add this one    
            if (this.state.selectedService.overrides != null) 
            {
                const parentServices = this.state.selectedService.overrides.split(',');
                for (let x=0; x < parentServices.length; x++){
                    let selectedServicesIDs= this.state.selectedServices.map(id => id.service_id);
                    let foundIndex = selectedServicesIDs.indexOf(parseInt(parentServices[x],10));
                    if ( foundIndex > -1){
                        totalCost = totalCost - selectedServices[foundIndex].service_cost;
                        discount = totalCost * this.state.selectedDiscount/100;
                        totalDiscounted = totalCost - discount;             
                        totalDuration = totalDuration - selectedServices[foundIndex].duration_days;
                        selectedServices.splice(foundIndex,1); 
                    }
                    this.setState( {message : "NOTE : Price may vary based on selected model"} );

                }

            } else {
// otherwise, see if its already , message that its already in there   

                for (let x=0; x < selectedServices.length; x++) {
                    if (selectedServices[x].overrides != null) {
                        const childServices = selectedServices[x].overrides.split(',');
                        let found = childServices.indexOf(this.state.selectedService.service_id.toString());
                        if (found > -1) {
                            goodToGo = 1;
                            this.setState( {message : "Already included in a selected service"} );
                        }
                    }
                }
            }
            if (goodToGo === 0) {
                let serviceSelected = {
                    description : this.state.selectedService.description,
                    service_category : this.state.selectedService.service_category,
                    service_cost : this.state.selectedService.service_cost,
                    service_discount : 0,
                    service_amount : this.state.selectedService.service_cost,
                    service_id : this.state.selectedService.service_id,
                    data_collect : this.state.selectedService.data_collect,
                    duration_days : this.state.selectedService.duration_days,
                    installer_category : this.state.selectedService.installer_category,
                    installer_id : this.state.selectedService.installer_id,
                    market_size : this.state.selectedService.market_size,
                    overrides : this.state.selectedService.overrides,
                    vehicle_size : this.state.selectedService.vehicle_size
                };               
                serviceSelected.service_discount = serviceSelected.service_cost * this.state.selectedDiscount/100;
                serviceSelected.service_amount = serviceSelected.service_amount - serviceSelected.service_discount;
                totalCost = totalCost + this.state.selectedService.service_cost;
                discount = totalCost     * this.state.selectedDiscount/100;
                selectedServices.push(serviceSelected);
                totalDiscounted = totalCost - discount;    
                totalDuration = totalDuration + this.state.selectedService.duration_days;
                this.setState( {selectedServices, totalCost, totalDiscounted, totalDuration});
                this.props.handleServices(selectedServices, totalCost, this.state.selectedDiscount);
                if (serviceSelected.service_cost === 0){
                    this.setState( {showCustom : true});
                }

            }
        }   
    }
}

calculateTotals = () => {
    //take the list of selected services and calculate the totals
    let totalCost = 0;
    let totalDiscounted = 0;
    let discount = 0;
    let totalDuration = 0;
//    console.log("How many services do we have :", this.state.selectedServices.length);
    if (this.state.selectedServices.length > 0) {
        totalCost = this.state.selectedServices.map(x => x.service_cost).reduce((a,c) => a + c);
//        console.log ("Total Cost :", totalCost);
        discount = totalCost * this.state.selectedDiscount/100;
        totalDiscounted = totalCost - discount;
        totalDuration = this.state.selectedServices.map(x => x.duration_days).reduce((a,c) => a + c);
//        console.log ("Total Duration :", totalDuration);
    }
        this.setState( {totalCost , totalDiscounted, totalDuration, estimateCost : 0, estimateDuration : 0});
//        console.log ("Full Array :", this.state.selectedServices)
        this.props.handleServices(this.state.selectedServices,totalCost,this.state.selectedDiscount);
    }


    removeService = (row) => {
          //remove the service to the list
          console.log("removeService : row : ", row)
          console.log("removeService : initial selectedServices : ", this.state.selectedServices);
          let selectedServices = Array.from(this.state.selectedServices).filter(service => service.service_id !== row.service_id);;
          const totalCost = this.state.totalCost - parseFloat(row.service_amount.replace(/\$|,/g, ''));
          const discount = totalCost * this.state.selectedDiscount/100;
          const totalDiscounted = totalCost - discount;  
          const totalDuration = this.state.totalDuration - row.duration_days;
          console.log("removeService : after selectedServices : ", selectedServices);
          this.setState( {selectedServices, totalCost, totalDiscounted, totalDuration, estimateCost : 0, estimateDuration : 0});
          this.props.handleServices(selectedServices, totalCost, this.state.selectedDiscount);  
        }

    handleCustomEstimate = (desc, cost, duration) => {
        let estimateDesc = desc;
        let estimateCost = parseFloat(cost);
        let estimateDuration = parseFloat(duration);
//        console.log("We got to update ", estimateDesc, estimateCost, estimateDuration)
        // update the selected item
        let selectedServices = [ ...this.state.selectedServices ];
        let index = selectedServices.findIndex(item => (item.service_cost === 0));
//        console.log("Found at :", index);
        if (index > -1) {
            selectedServices[index].description = estimateDesc;
            selectedServices[index].service_cost = estimateCost;
            selectedServices[index].duration_days = estimateDuration;
            selectedServices[index].service_discount = selectedServices[index].service_cost * this.state.selectedDiscount/100;
            selectedServices[index].service_amount = selectedServices[index].service_cost - selectedServices[index].service_discount;
        }
 //       console.log ("Post Services :", selectedServices);
        // recalculate the totals 
        this.setState( {selectedServices, estimateDesc, estimateCost, estimateDuration, showCustom : false});
        this.calculateTotals();
    }
    

    handleCategoryChange = (event) => {

        this.setState({ selectedService: {description: ''}, displayServices: []});

        const { value } = event.target;

        const dispServices = this.state.services.filter(service => service.service_category === value);
        const displayServices = dispServices.map(item => item.description);

        this.setState({ displayServices, selectedCategory: value, estimateCost : 0, estimateDuration : 0 , estimateDesc : ""});
        
    }

    handleServiceChange = (event) => {

        const { value } = event.target;
        const service = this.state.services.find(service => ( service.description === value && service.service_category === this.state.selectedCategory ));
        this.setState({ selectedService: service });

    }

    displayDisc = () => {
        this.setState( {showDisc : true})
    }

    removeDisc = () => {
        let total = this.state.totalCost;
//        console.log("Here's the data in State :", this.state)
                // recalculate service discounts
                let invoiceServices = Array.from(this.state.selectedServices);
                for (let i=0; i < invoiceServices.length; i++){
                    invoiceServices[i].service_discount = 0;
                    invoiceServices[i].service_amount = invoiceServices[i].service_cost;
                }
        
        this.setState({selectedDiscount : 0, discount : 0, totalDiscounted : total, selectedServices : invoiceServices})
        this.props.handleServices(invoiceServices, this.state.totalCost, 0);

    }

    handleDiscountChange = (discountPercent) => {
        // recalculate service discounts
        let invoiceServices = Array.from(this.state.selectedServices);
        for (let i=0; i < invoiceServices.length; i++){
            invoiceServices[i].service_discount = invoiceServices[i].service_cost * discountPercent / 100;
            invoiceServices[i].service_amount = invoiceServices[i].service_amount - invoiceServices[i].service_discount;
        }
        console.log("invoice services :", invoiceServices);
        console.log("State :", this.state);
        let discount = this.state.totalCost * discountPercent / 100;
        const totalDiscounted = this.state.totalCost - discount;
        this.setState( {selectedDiscount : discountPercent , totalDiscounted, discount,showDisc : false, selectedServices : invoiceServices});
        this.props.handleServices(this.state.selectedServices, this.state.totalCost, discountPercent)
    }

    addCustomClose = () => {
        // find the item in the selected list where the cost = 0 and remove it
            let selectedServices = [ ...this.state.selectedServices ];
            let index = selectedServices.findIndex(item => (item.service_cost === 0));
            if (index > -1) {
                selectedServices.splice(index, 1);
            }
            this.setState( {showCustom : false, selectedServices, showDisc : false});
        };


    handleJobSelect = async (selectRequest) => {
    // Populate the selected list with the items in the job
    // read each service from the job selected and call addService for each
        console.log( "Service Form : Select Request : ", selectRequest);
        const { data: services } = await API.get(`/requestServices/${selectRequest.Request_id}`);
        console.log("Service Form : Selected Services : ", services);

        for (let i= 0; i < services.length; i++ ){
            this.setState( {selectedService : services[i]});
            this.addService();            
        }
    // then close the window

    this.setState( {showJobList : false});
    };
    

    handleClose = () => {
        // find the item in the selected list where the cost = 0 and remove it
        this.setState( {showJobList : false});
        if (this.props.currentStep === 99) {
            const clickEvent = new Event("click");
            this.props.onCancel(clickEvent);
        }
    };
    

    render() {
        
        const columns = [  {
            dataField: "delete",
            text: "Action",
            formatter: (_, row) => (
                <i className="fa fa-trash text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => this.removeService(row)}
                ></i>
            ),
            headerStyle: { width: "80px", textAlign: "center" },
            align: "center",
          },{
            dataField: 'service_category',
            text: "Category"
        }, {
            dataField: 'description',
            text: "Service"
        }, {
            dataField: 'service_amount',
            text: "Amount"
        }, {
            dataField: 'duration_days',
            text: "Duration"
        }];

          

     //   console.log("currentStep & userType", this.props.currentStep, this.props.userType);
        
        if ((this.props.currentStep !== 3) && 
        (this.props.currentStep !== 99)){
            return null
        }

        const selectRow = { mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {this.removeService(row, rowIndex)}} ;
  
        console.log( "serviceForm : selectedServices :", this.state.selectedServices )
        let selectedServices = [ ...this.state.selectedServices ];
        selectedServices = selectedServices.map(selectedService => {
            let selectedservice_ = Object.assign({}, selectedService);
            selectedservice_.service_amount = "$"+(selectedservice_.service_amount - selectedservice_.service_discount).toFixed(2);
            return selectedservice_;
        });
        
        return (
            <React.Fragment>
                <form className="bg-request p-5">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="vin" className="bold">Pick Service(s)</label>
                        </div>
                        <div className="form-group col-md-3">
                            <Select
                                name="category"
                                label=" "
                                options={this.state.categories}
                                value={this.state.selectedCategory}
                                onChange={this.handleCategoryChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <Select
                                name="service"
                                label=" "
                                options={this.state.displayServices}
                                value={this.state.selectedService.description}
                                onChange={this.handleServiceChange}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <button className="btn btn-block btn-success" type="button" disabled={!this.state.selectedService.description}
                            onClick={this.addService}>
                                Add Service
                            </button>
                        </div>
                        <AddCustomCut
                        show={this.state.showCustom}
                        onHide={this.addCustomClose}
                        estimateDesc={this.state.estimateDesc}
                        estimateCost={this.state.estimateCost}
                        estimateDuration={this.state.estimateDuration}
                        handleCustomEstimate={this.handleCustomEstimate}
                        addService={this.addService}
                        />
                        <AddDiscount
                        show={this.state.showDisc}
                        onHide={this.addCustomClose}
                        discountValue={this.state.discount}
                        jobTotal={this.state.totalCost}
                        handleDisc={this.handleDiscountChange}
                        />

                    </div>
                { this.props.userType !== "dealer" && this.state.selectedServices.length > 0 && 
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-4">
                            <button 
                                className="btn shadow btn-success" 
                                disabled={!this.state.selectedService.description && this.state.selectDiscount}
                                type="button" onClick={this.displayDisc}>
                                Add Discount
                            </button>
                            <button 
                                className="btn shadow btn-success" 
                                disabled={!this.state.selectedDiscount}
                                type="button" onClick={this.removeDisc}>
                                Remove Discount
                            </button>
                        </div>
                        </div>}

                </form>

                {this.state.selectedServices.length > 0 && <div>
                <h5 className="text-center bold p-5">{this.state.message}</h5>
                <div className="text-center table-responsive">
                    <BootstrapTable 
                        keyField='service_id' 
                        data={selectedServices} 
                        columns={columns} 
                    />
                </div>
                <div className="row">
                    <div className="col text-right">
                        {this.state.selectedServices && <div>Total Cost: ${this.state.totalDiscounted.toFixed(2)}</div>}
                    </div>
                    {this.state.discount > 0 && <div className="col text-center"> Discount Applied : ${this.state.discount.toFixed(2)}</div>}
                    <div className="col text-left">
                        {this.state.selectedServices && <div>Service Duration: {this.state.totalDuration} days</div>}
                    </div>
                </div>
                </div>}
                <SelectCustomerJob
                    showJobList = {this.state.showJobList}
                    onHide = {this.handleClose}
                    jobs = {this.state.custJobs}
                    onSelect = {this.handleJobSelect}
                    mode = "select"
                    step = {this.props.currentStep}
                />

            </React.Fragment>
        );
    };

};
