import React, { Component } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';


export class SelectCalendar extends Component {

  state = {
    selectedCalendar : "X"
  }


  handleSelect = async () => {
    this.props.onSelectCalendar(this.props.calendars[this.state.selectedCalendar])
  };

// In this modal, we'll display the list of vehicles we have already done work for 
// the user can pick one and it will populate the dropdowns or cancel and ad a new one

    render(){
//      console.log("selectCalendar : Calendars we have :", this.props.calendars);


    const columns = [{
        dataField: 'name',
        text:"Calendar"}
    ];

      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor : 'grey',
        onSelect: (e, row, rowIndex) => {
          this.setState({selectedCalendar : rowIndex});
//          console.log ("Row selected : ", this.state.selectedCalendar);
          //            this.props.onSelect(row);
        }
      };

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          this.setState({selectedCalendar : rowIndex});
//          console.log ("Row selected : ", this.state.selectedCalendar);
        }};

      return(
            <Modal
            show={this.props.showList}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Select the Google Calendar to manage this location's appointments
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
              <BootstrapTable
                  keyField='id'
                  data={this.props.calendars}
                  columns={columns}
                  selectRow={selectRow} 
                  rowEvents={ rowEvents }/>
              </div>
            {this.state.selectedCalendar !== "X" && <button type="button" onClick={() => this.handleSelect()} className="btn btn-success m-2 buttons">Select</button>}
            <button type="button" onClick={() => this.props.onHide()} className="btn btn-danger m-2 buttons">Cancel</button>

            </Modal.Body>
          </Modal>
        );
    };
};