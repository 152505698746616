// This part of the form will collect the Available data and Due Date of the job
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class DatesForm extends Component {
  
  state = {
    time: new Date(),
    isOpen: false,
  }

  addDays(date, days) {
    let result = moment(date).format("YYYY/MM/DD");
    let results = moment(result).add(days, "days");
    return results;
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
  }

  handleSelect = (time) => {
    this.setState({ time, isOpen: false });
  }

  state = {
    errors: {},
    vehicle: this.props.vehicle
  }

  render() {

    if (this.props.currentStep !== 5) { // Prop: The current step
      return null;
    }

    let minAvailableDate = new moment(new moment().format("YYYY/MM/DD")).toDate();
    console.log("THe vehicle we have here is : ", this.props.vehicle);

    let diff = 1.0;
    if(this.props.duration > 1){
      diff = Math.round(this.props.duration);
    }
    
    let availableDate = new moment(this.props.availableDate).toDate();
    let minDueDate = this.addDays(availableDate,diff).toDate();
      let dueDate = minDueDate;
      if (minDueDate < moment(this.props.dueDate)){
        dueDate = new moment(this.props.dueDate).toDate();
      }
      
      let displayVehicle = this.props.vehicle.year + " " + this.props.vehicle.make +" "+ this.props.vehicle.model;
      // build the display services string for the selected services
      let displayServices = this.props.services[0].service_category + " " + this.props.services[0].description;
      for (var x=1; x < this.props.services.length; x++){
        if (x === this.props.services.length - 1)
         displayServices = displayServices + " & ";
         else
         displayServices = displayServices + ", ";
        displayServices = displayServices + this.props.services[x].service_category + " " + this.props.services[x].description
        }


    return (
      <React.Fragment>
        <h5 className="text-center bold mb-4">Work requested will take {this.props.duration} days to complete</h5>
        <form className="bg-request p-5 justify-content-md-center">
          <div className="form-row justify-content-md-center">

            <div className="form-group col-md-2 text-center">
              <label htmlFor="vin" className="bold">Available</label>
            </div>

            <div className="form-group col-md-4 text-center">
              <DatePicker
                name="availableDate"
                selected={availableDate}
                value={availableDate}
                minDate={minAvailableDate}
                onChange={this.props.handleAvailableDate}
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="MMM do yyyy h:mm aa"
                timeCaption="time"
                className="form-control"
                showTimeSelect
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>

            <div className="form-group col-md-2 text-center">
              <label htmlFor="vin" className="bold">Needed by</label>
            </div>
            <div className="form-group col-md-4 text-center">
              <DatePicker
                name="dueDate"
                selected={dueDate}
                value={dueDate}
                minDate={minDueDate}
                onChange={this.props.handleDueDate}
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="MMM do yyyy h:mm aa"
                timeCaption="time"
                className="form-control"
                showTimeSelect
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </form>
        <p className="text-center bold">{displayVehicle}</p>
        <p className="text-center bold">{displayServices}</p>
        {this.props.notes && <p className="text-center bold">{this.props.notes}</p>}

        <p className="text-center bold">
          Available from {moment(this.props.availableDate).format("dddd, MMM Do h:mm a")} through {moment(this.props.dueDate).format("dddd, MMM Do h:mm a")}
        </p>
      </React.Fragment>
    );

  }

}
