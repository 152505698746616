/* Three steps 

1. Collect the customer information and store that 
2. Display the form for the customer to sign
3. Update the request and store the doc in S3

The requestId should be passed in as a parameter with the request id

Read the request information and the vehicle information and the services but read the retail prices for them
not the wholesale prices
Read the Installer information as well

*/

// This will be the header unit for the wizard layout.

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import CustomerForm from './../../customerForm';
import ViewContract from './displayContract';
import { HTTPService } from "./../../../services/httpService";
import ReactGA from 'react-ga4';
import axios from 'axios';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const API = new HTTPService();

require('dotenv').config();

class ContractForm extends Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: false,
      currentStep: 1,
      customer: "",
      selectedRequest: "",
      vehicle: "",
      services: [],
      dealerships : [],
      dealer : {
        dealerName : "Steve Dealer",
        dealerAddress : "Glenwood"
      },
      installer : {
        installerName : "Dave Installer",
        installerEmail : "Westgate",
        services : "retail"
      },
      loading:false,
      user: this.props.user,
      show : false,
      signed : false
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomer = this.handleCustomer.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

  }

  async componentDidMount() {
// read the request information
    let dispRequest = `/requestimates/fullrequest/?Id=${this.props.match.params.requestId}`;        
    const  { data : selectedRequests }  = await API.get(dispRequest);
    this.setState({ selectedRequest : selectedRequests[0]})

console.log("contractForm : request details : ", this.state.selectedRequest)

// read the wholesale services and return the corresponding retail services
    let svcRequest = `/requestWholeService/${this.props.match.params.requestId}/${this.state.selectedRequest.assignedTo}`;        
    const  { data : services }  = await API.get(svcRequest);
    this.setState({ services})

// read the installer information and set the catalog detail to "RETAIL" to collect customer information
    let installerRequest = `/installers/${this.state.selectedRequest.assignedTo}`;        
    const  { data : installers }  = await API.get(installerRequest);
    installers[0].services = "retail";
    this.setState({ installer : installers[0]})

    // read the request information
    let dealRequest = `/dealers/${this.state.selectedRequest.requestor}`;        
    const  { data : dealers }  = await API.get(dealRequest);
    this.setState({ dealer : dealers[0]})


    console.log("Contract Form : Request : ", this.state.selectedRequest)
    console.log("Contract Form : Services : ", this.state.services)
    console.log("Contract Form : Installer : ", this.state.installer)
    console.log("Contract Form : Dealer : ", this.state.dealer)
    console.log("looking for email address :", this.props);
  };


  handleSubmit = async e => {
    e.preventDefault();
// add the vehicle to the vehicle table
// need vin, {year, make, model & trim, size and color} for Vehicle in Props
    this.setState({ loading: true });

    const newRequest = await this.addRequest();
    alert("Adding this dealer work to your calendar"); 
    window.location = "/jobs";
    ReactGA.event({category: 'Installer', action: 'Created Job', label: 'prod'});
    return;  

}

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  handleCancel = e => {
    e.preventDefault();
    ReactGA.event({category: 'Dealer', action: 'Cancel Contract', label: 'prod'});
    this.props.history.push("/requestimates");
    return;
  }

  handleCustomer(customerId, customerName, customerType, customer) {
        console.log("Handle Customer selection", customer);
        this.setState({customer, customerId});
      }

  handleDisplay = () => {
        this.setState({show : true});
      }

  handleClose = () => {
        this.setState({show : false, loading : false});
  }
  
  handleClose = () => {
        window.location = "/requestimates";
  }
    
  handleSignature = async () => {
                this.setState({ loading: true });

                console.log("Handle Signature");
                const contractRef = document.getElementById("contract-modal");

                const canvas = await html2canvas(contractRef, { scale: 2 });
                const imgData = canvas.toDataURL("image/png");
          
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(imgData, "PNG", 10, 10, 190, 250);
          
                const pdfBlob = pdf.output("blob");

//                const pdfUrl = URL.createObjectURL(pdfBlob);
//                window.open(pdfUrl, "_blank");

                console.log("Request information : ", this.state.selectedRequest);
                console.log("Customer Information : ", this.state.customer);

          
                const formData = new FormData();
                formData.append("file", pdfBlob, `contract_${this.state.selectedRequest.requestId}.pdf`);
                formData.append("requestId", this.state.selectedRequest.requestId);
                formData.append("customerId", this.state.customerId);
                formData.append("dealerEmail", this.props.user.Email);
                formData.append("customerEmail", this.state.customer.customer_Email_Address);

                // Save the signed contract to S3 
                // Upload PDF to backend
                try{
                await axios.post("uploadContract", formData, {
                  baseURL: process.env.REACT_APP_API_URL,  
                })
              } catch (err) {
                console.error("Upload failed:", err.response?.data || err.message);
              }

                this.setState( {signed : true})
                await this.handleClose();
               alert ("Contract is complete. Copies have been emailed to the dealer and customer and the specialist has been notified");
  // Store the URI and the on behalf of value in the request (done when saving the contract) */

           }
            

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1, then add one on "next" button click
    currentStep = currentStep >= 2 ? 4 : currentStep + 1
    this.setState({ currentStep: currentStep })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({ currentStep: currentStep })
  }

  // The "next" and "previous" button functions
  get previousButton() {

    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (<button className="btn btn-success buttons" type="button" onClick={this._prev}> PREV</button>)
    }

    // ...else return nothing
    return null;

  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 4, then render the "next" button
    if(currentStep < 3){
        let disable = true;
        if (currentStep === 1 && this.state.customer) {
          disable = false;
        }
        if (currentStep === 2 && this.state.contract) {
          disable = false;
        }
      return (
        <button 
          className="btn btn-success float-right buttons" 
          type="button" disabled={disable} onClick={this._next}>
        NEXT
        </button>        
      )
    } else {
      // ... must be the submit time...
      return (
        <button 
          className="btn btn-primary float-right" 
          type="button" onClick={this.handleSubmit}>
                            {this.state.loading && <span>
                                    <i
                                    className="fa fa-refresh fa-spin"
                                    />
                                </span>}
                                {!this.state.loading && <span>Contract Signed</span>}

        </button>        
      ) 
    }

  };

  // This is also where when all the validation is completed, we push the new record
  // and redirect the page to the requestimate form with the parameters set
  render() {


    return (
      <React.Fragment>
        <div className="bg-white border border-secondary">
          <div className="col-lg-12 text-right">
            <button className="btn" type="button" onClick={this.handleCancel}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="col-lg-12 p-0">
            <CustomerForm 
                currentStep={this.state.currentStep} 
                userType = "installer"
                vehicle={this.state.vehicle}
                availableDate = {this.state.availableDate}
                dueDate = {this.state.dueDate}
                services={this.state.services}
                notes = {this.state.notes}
                dealerships = {this.state.dealerships}
                handleCustomer={this.handleCustomer}
                installer={this.state.installer}
                mode={"add"}
                />       
              <ViewContract
                userType={this.props.userType}
                typeId={this.props.typeId}
                currentStep={this.state.currentStep} 
                customer={this.state.customer}
                request={this.state.selectedRequest}
                vehicle={this.state.vehicle}
                services={this.state.services}
                dealer={this.state.dealer}
                installer={this.state.installer}
                contract={this.state.contract}
                handleSignature={this.handleSignature}
                show={this.state.show}
                handleClose={this.handleClose}
                handleDisplay={this.handleDisplay}
                signed={this.state.signed}
                handleComplete={this.handleComplete}
                loading={this.state.loading}
            />


            <div className="m-5 d-flex justify-content-center">
              <div className="p-2">
                {this.previousButton}
              </div>
              <div className="p-2">
                {this.nextButton}
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  };

}
export default withRouter(ContractForm);
