import React, { Component } from 'react';
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _ from 'lodash';
import SignatureCanvas from 'react-signature-canvas';

//              <SignaturePad clearButton="true" />

// var SignaturePad = require('react-signature-pad');



export default class ViewContract extends Component {

  constructor(props) {

    super(props);

    this.state = {
    }
  }

    render(){

      if (this.props.currentStep !== 2) {
        return null
    };

//      console.log ("Display Contract : Customer  :", this.props.customer);

      let dealerAddress = `${this.props.dealer.dealer_address_line1}, ${this.props.dealer.dealer_address_city}, ${this.props.dealer.dealer_address_state} ${this.props.dealer.dealer_address_zip}`;

      let contractData = {
        customerName : this.props.customer.customer_name,
        customerAddress : this.props.customer.customer_address,
        customerEmail : this.props.customer.customer_Email_Address,
        vehicleYear :this.props.request.vehicleYear,
        vehicleMake :this.props.request.vehicleMake,
        vehicleModel : this.props.request.vehicleModel,
        vehicleTrim : this.props.request.vehicleTrim,
        vin : this.props.vehicle.vin,
        dealerName : this.props.dealer.dealer_name,
        dealerAddress : dealerAddress,
        installerName : this.props.installer.Installer_Name,
        installerEmail : this.props.installer.installer_email,
        date : moment().format("MM/DD/YYYY"),
      }     
      const services = this.props.services;
      const columns = [{
        dataField: 'category',
        text: "Category"
        }, {
        dataField: 'desc',
        text: "Service"
        }, {
        dataField: 'amount',
        text: "Amount"
        }];

        let services_ = [];
        for (let i=0; i < services.length; i++){
          let rowView = {
            serviceId : "",
            category :"",
            desc : "",
            amount : "",
          };
          rowView.serviceId = services[i].service_id;
          rowView.category = services[i].service_category;
          rowView.desc = services[i].description;
          if(typeof(services[i].service_amount) === "string"){
              rowView.amount = services[i].service_cost;
          } else {
            rowView.amount = "$" + services[i].service_cost.toFixed(2);
          }  
          services_.push(rowView);
        }
    
      return(

        <div className="d-flex flex-column align-items-center justify-content-center">
        {!this.props.signed && (
          <div className="d-flex flex-column align-items-center text-center">
            <p className="fs-5">Click the button below to view the contract.</p>
            <Button variant="primary" onClick={this.props.handleDisplay}>
              Show Contract
            </Button>
          </div>
        )}
      
        {this.props.signed && (
          <div className="d-flex flex-column align-items-center text-center">
            <p className="fs-5">
              Contract details have been completed and copies emailed to all parties.
            </p>
            <br />
            <Button variant="primary" onClick={this.props.handleComplete}>
              Complete
            </Button>
          </div>
        )}

        <Modal show={this.props.show} onHide={this.props.handleClose} size="xl" centered>
          <div id='contract-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Paint Protection Film & Window Tint Purchase Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          <Row className="mb-3">
              <Col>
                <h6>This agreement is made and entered into on <strong>{contractData.date}</strong></h6>
              </Col>
            </Row>
          <Row className="mb-3">
              <Col>
                <h5>CUSTOMER INFORMATION</h5>
                <p><strong>Name:</strong> {contractData.customerName}</p>
                <p><strong>Address:</strong> {contractData.customerAddress}</p>
                <p><strong>Email:</strong> {contractData.customerEmail}</p>
              </Col>
            </Row>
            <p></p>
            <Row className="mb-3">
              <Col><h5>VEHICLE INFORMATION</h5></Col>
            </Row>
            <Row>
                <Col><strong>Year:</strong> {contractData.vehicleYear}</Col>
                <Col><strong>Make:</strong> {contractData.vehicleMake}</Col>
                <Col><strong>Model:</strong> {contractData.vehicleModel}</Col>
                <Col><strong>Trim:</strong> {contractData.vehicleTrim}</Col>
            </Row>
            <p></p>
            <Row className="mb-3">
              <Col><h5>DEALER INFORMATION</h5></Col>
            </Row>
            <Row>
                <Col><strong>Name:</strong> {contractData.dealerName}</Col>
                <Col><strong>Address:</strong> {contractData.dealerAddress}</Col>
            </Row>
            <p></p>
            <Row className="mb-3">
              <Col><h5>CERTIFIED INSTALLER INFORMATION</h5></Col>
            </Row>
            <Row className="mb-3">
              <Col><strong>Name:</strong> {contractData.installerName}</Col>
              <Col><strong>Email:</strong> {contractData.installerEmail}</Col>
            </Row>
            <Row className="mb-3">
              <Col>
              <h5>PURCHASED PACKAGES</h5>
            <BootstrapTable 
              keyField='serviceId' 
              data={ services_ } 
              columns={ columns } 
            />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col><h5>TERMS AND CONDITIONS</h5></Col>
            </Row>
            <Row className="mb-3">
              <Col>
              <p><strong>Professional Installation :</strong> The dealership utilizes {contractData.installerName} as a professional installer to complete the work. The performance of the installation will
              adhere to industry standards.</p>
              <p><strong>Issue Resolution :</strong> Any concerns or issues regarding the work performed should be addressed in-writing by contacting the installer directly via email at :
              {contractData.installerEmail}. Customers are encouraged to schedule a consultation for any installation-related inquiries.</p>
              <p><strong>Limitation of Liability :</strong> The dealership shall not be held liable for any issues arising from the installation of the package.</p>
              </Col>
              
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        <Row className="mb-3">
              <Col>
              <SignatureCanvas penColor='black' backgroundColor='rgba(211, 211, 211, 1)'
    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
              </Col>
            </Row>
            <button 
          className="btn btn-primary float-right" 
          type="button" onClick={this.props.handleSignature}>
                            {this.props.loading && <span>
                                    <i
                                    className="fa fa-refresh fa-spin"
                                    />
                                </span>}
                                {!this.props.loading && <span>Sign Here</span>}

        </button>        
        <Button variant="secondary" onClick={this.props.handleClose}>Close</Button>
        </Modal.Footer>
        </div>
      </Modal>
      </div>

        );
    };
};