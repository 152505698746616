// This will be the header unit for the wizard layout.
import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import ServiceForm from './../../serviceForm';
import { HTTPService } from "./../../../services/httpService";
import { convertToUTC } from '../../../util/convert_timezone';
import ReactGA from 'react-ga4';
import _ from 'lodash';

const API = new HTTPService();

class EditJob extends Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: false,
      currentStep: 99,
      platformFee : 0.05,
      customer: "",
      customerName:"",
      customerType:"",
      vehicle: {},
      services: [],
      total : 0,
      discount : 0,
      jobType : "Retail",
      fee : 0,
      loading:false,
      notes:"",
      taxRate : 0,
      taxPercent : 0,
      availableDate: new moment().startOf('day'),
      dueDate: new moment().startOf('day'),
      user: this.props.user,
      authenticated : 0,
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleServices = this.handleServices.bind(this);
    
  }

  async componentDidMount() {
    
    let installerRequest = "/installerLocations/"+this.props.typeId;       
    const  { data : installers }  =  await API.get(installerRequest);

    let taxRequest = "/taxes/"+installers[0].stripe_tax_rate;        
    const  { data : taxRate }  =  await API.get(taxRequest);

    let serviceRequest = `/requestService/${this.props.match.params.requestId}`;        
    const  { data : services }  =  await API.get(serviceRequest);
    for (let i=0; i < services.length; i++){
      if (services[i].custom_description){
        services[i].description = services[i].custom_description;
      }
    }  

    let fullRequest = `/requestimates/requestbyID/?Id=${this.props.match.params.requestId}`;        
    const  { data : requests }  =  await API.get(fullRequest);

    let calendarRequest = `/installerCalendarRequest/${this.props.match.params.requestId}`;        
    const  { data : calendar }  =  await API.get(calendarRequest);

    let vehicleRequest = `/vehicles/${requests[0].vin}`;        
    const  { data : vehicles }  =  await API.get(vehicleRequest);

    let authRequest = `/installerAuth/${this.props.typeId}`;        
    const  { data : auths }  =  await API.get(authRequest);

    let catalogType = "RETAIL";
    if (requests[0].Request_type === "dealer")
        catalogType = "WHOLE";
    
    this.setState({ vehicle : vehicles[0], auth : auths[0], taxPercent : taxRate.percentage, services, calendar , eventId : calendar[0].event_id,  jobType : requests[0].Request_type, 
      installer : installers[0], request : requests[0], total : requests[0].Request_Total, discount : requests[0].Request_Discount, customerType : catalogType});  

//   console.log("EditJob : installer :", this.state.installer);
//    console.log("EditJob : taxes :", this.state.taxPercent);
//    console.log("EditJob : services :", this.state.services);
//    console.log("EditJob : request :", this.state.request);
//    console.log("EditJob : vehicle :", this.state.vehicle);
//      console.log("EditJob : eventId :", this.state.eventId);
//      console.log("EditJob : auth :", this.state.auth);
      
  };

  /* Processing to do to update the job in the database */

  handleSubmit = async e => {
    e.preventDefault();

    console.log("editJob : Full State :", this.state);

// set up the values for the request update
    let newRequest = this.state.request;
 
    newRequest.Request_Total = this.state.total;
    
    // process the services requested array to create the right value
    newRequest.request_duration = this.state.services.reduce(function(prev, cur) {
      return prev + cur.duration_days;
    }, 0); 
    newRequest.Service_Requested = this.state.services[0].service_category + " " + this.state.services[0].description;
    if (this.state.services.length > 1) {
      newRequest.Service_Requested = newRequest.Service_Requested + " + " + (this.state.services.length-1);
    }
    newRequest.Request_Fee = newRequest.Request_Total * this.state.platformFee;
    
    if (this.state.discount > 0){
      newRequest.Request_Discount = this.state.discount;
      newRequest.Request_Total = newRequest.Request_Total - (newRequest.Request_Total * this.state.discount / 100);
    };

    if (newRequest.requestType === "dealer")
      newRequest.Request_taxes = 0;
    else
      newRequest.Request_taxes = newRequest.Request_Total * this.state.taxPercent/100;

    // calculate a new due date for the request based on the work added
    newRequest.due_date = new moment(newRequest.available_date).add(newRequest.request_duration, "days").format("YYYY-MM-DD HH:MM:SS"); 

    // convert dates to correct format - in UTC for some reason.... need to find out why
    newRequest.created_date = new moment(newRequest.created_date).format("YYYY-MM-DD HH:MM:SS"); 
    newRequest.available_date = new moment(newRequest.available_date).format("YYYY-MM-DD HH:MM:SS"); 
    

      console.log("handleSubmit : updated request : ", newRequest);
    
      console.log("handleSubmit : calendar entries : ", this.state.eventId);    

      console.log("handleSubmit : services : ", this.state.services);

// delete the current services and installer calendar entries
      let delServices = await this.deleteServices(newRequest.Request_id);
      let delOffer = await this.deleteOffer(newRequest.Request_id, newRequest.Assigned_to);
      let delCalendar = await this.deleteCalendars(newRequest.Request_id, newRequest.Assigned_to);
      let updateRequest = await this.updateRequest(newRequest);
      let addService = await this.addServices();
      let addCalendar = await this.addCalendar(this.state.calendar[0].work_date);

      // if there is an event id, then need to update the calendar appointment
     if (this.state.eventId) {
      let updCalendar = await this.updateAppointment(newRequest.Request_id,newRequest.Assigned_to );
      let entry = {
          startDate : this.state.calendar[0].work_date,
          year : this.state.vehicle.Year,
          make : this.state.vehicle.Make,
          model : this.state.vehicle.Model,
          serviceSummary : newRequest.Service_Requested,
          requestDuration :  newRequest.request_duration
      };
      let updGoogleCalendar = await this.updateGoogleAppointment(entry);
      };

      // add edit record to the request audit trail

      let addStatus = await this.addRequestState();

      // manage the records correctly depending on the state of the request
      switch (newRequest.current_state){
        case "scheduled" : await this.updateOffer(newRequest.Request_id,newRequest.Assigned_to,"scheduled"); await this.updateCal(newRequest.Request_id,newRequest.Assigned_to,"booked"); break;
        case "received" :await this.updateOffer(newRequest.Request_id,newRequest.Assigned_to,"received"); await this.updateCal(newRequest.Request_id,newRequest.Assigned_to,"booked"); break;
        case "started" : await this.updateOffer(newRequest.Request_id,newRequest.Assigned_to,"started"); await this.updateCal(newRequest.Request_id,newRequest.Assigned_to,"booked"); break;
        default : break;
      }

     ReactGA.event({category: 'Installer', action: 'Updated Estimate', label: 'prod'});
      alert("Updating the services on this job"); 
      if (newRequest.current_state === "new"){
        window.location='/requestimates';
      } else {
        window.location='/jobs';
      } 
  };

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  handleCancel = e => {
    e.preventDefault();
    ReactGA.event({category: 'Installer', action: 'Cancel Edit', label: 'prod'});
    if (this.state.request.current_state === "new"){
      window.location='/requestimates';
    } else {
      window.location='/jobs';
    }
    return;
  }

  handleServices(services,total, discount) {
    this.setState({services , total, discount})
//    console.log ("Handle Services :", services, total, discount, duration)
  }

  readAuth = async (installerId) => {
    let installerRequest = `/installerAuth/${installerId}`;        
    const  { data : installer }  =  await API.get(installerRequest);
  //  console.log("Installer :", installer);
    this.setState( {authenticated : installer[0].authenticated});
  }

     addServices = async () => {
      const newRequestService = {
        requestId: this.state.request.Request_id,
        serviceId: "",
        serviceAmount: 0,
        serviceDiscount: 0,
        serviceDuration: 0,
        serviceFee: 0,
        serviceState: "new",
        serviceDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
        customDescription : "" // new moment().format("YYYY/MM/DD HH:mm:ss")
      }
  
      for (let i = 0; i < this.state.services.length; i++) {
          newRequestService.serviceId = this.state.services[i].service_id;
          newRequestService.customDescription = this.state.services[i].description;
          newRequestService.serviceAmount = this.state.services[i].service_cost;
          if (this.state.discount > 0){
            newRequestService.serviceDiscount = newRequestService.serviceAmount * this.state.discount/100;
          }
          newRequestService.serviceFee = newRequestService.serviceAmount * this.state.platformFee;
          newRequestService.serviceDuration = this.state.services[i].duration_days;
          await API.post("/requestService", newRequestService);
      }
      return null;
    }

    addCalendar = async (startDate) => {
      let calendar = {
        requestId: this.state.request.Request_id,
        installerId : this.state.installer.Installer_id,
        installerCapacity : this.state.installer.capacity,
        startDate : startDate
      }
      // take the work requested and create the draft calendar entries 

      console.log ("addCalendar : calendar entry : ", calendar);
      const { data : newCalendar } = await API.post(`/schedule/`, calendar)
      
      return newCalendar;
      }


   addRequestState = async () => {    
      const state = {
          requestId: this.state.request.Request_id,
          stateDate: new moment().format('YYYY/MM/DD hh:mm:ss'),
          requestStatus: "edited"
      };
      
      const apiEndpoint = `/requestState`;
      await API.post(apiEndpoint, state);
      return null;
      
    };
      
    updateAppointment = async (request, installer) => {
      let apptUpdate = `/installerCalendarEvent/${this.state.eventId}/${request}/${installer}`;        
      await API.put(apptUpdate);
      return;
    };

    updateOffer = async (request, installer, state) => {
      let offerUpdate = `/requestoffered/state/${request}`;        
      
      let offer = {
          requestId : request,
          installerId : installer,
          currentStatus : state,
          dateUpdated : new moment().format('YYYY/MM/DD hh:mm:ss') 
        }
      
      await API.put(offerUpdate, offer);
      return;
    };

    updateCal = async (request, installer, state) => {
      let apptUpdate = `/installerCalendars/${installer}`;  
      
      let appt = {
            requestId: request,
            installerId: installer,
            workStatus: state
          };
            
      await API.put(apptUpdate, appt);
      return;
    };

    updateGoogleAppointment = async (calEntry) => {
    // calculate the end date/time from the start date and duration
    let localStartDate = new Date(calEntry.startDate);
    // convert start time to 4am UTC
    localStartDate.setHours(9);
    localStartDate.setMinutes(0);
    localStartDate.setMilliseconds(0);
//    localStartDate.setHours(localStartDate.getHours() + startHours);
    console.log ("Local start datetime :", localStartDate);
    let localEndDate = new Date(localStartDate);
    let hoursToAdd = calEntry.requestDuration * 8;
    if (calEntry.requestDuration > 1) {
      hoursToAdd = 8;
  }
    localEndDate.setHours(localEndDate.getHours() + hoursToAdd);
//    console.log ("calendarId :", this.state.auth.calendar_id);
    let timezone = "America/New_York";

        let summary = `${calEntry.year} ${calEntry.make} ${calEntry.model} ${calEntry.serviceSummary}`;
         let newAppt = {
            installerId : this.state.installer.Installer_id,
            calendarId : this.state.auth.calendar_id,
            eventId : this.state.eventId,
            startDate : localStartDate,
            endDate : localEndDate,
            summary : summary,
            timezone : timezone,
            description : "",
            duration : calEntry.requestDuration,
         };
         let apptUpdate = `/updAppt/`;        
         const { data: addAppt } = await API.post(apptUpdate, newAppt); 
         return;
     };

       updateRequest = async (newRequest) => {

      
         let requestUpdate = `/requestimates/fullrequestbyID/?Id=${this.state.request.Request_id}`;        
         const { data: updReq } = await API.put(requestUpdate, newRequest);
         return;
     };
      
  
     deleteServices = async (requestId ) => {
    
      await API.delete(`/requestService/${requestId}`);
      return;

    }
  
    deleteOffer = async (requestId, installerId ) => {

      await API.delete(`/requestoffered/installer/${requestId}/${installerId}`);
      return;

    }
    
    deleteCalendars = async (requestId, installerId ) => {
    
      await API.delete(`/installerCalendarRequest/${requestId}/${installerId}` );
      return;

    }
      
    // This is also where when all the validation is completed, we push the new record
  // and redirect the page to the requestimate form with the parameters set
  render() {


    // console.log("EditJob : selectedRequest ", this.props.match.params.requestId);
    ReactGA.send({ hitType: "pageview", page: "/editJob" })
    let displayType = "Retail";
    if (this.state.jobType === "dealer")
      displayType = "Wholesale";

  return (
      <React.Fragment>
        <div className="bg-white border border-secondary">
          <div className="col-lg-12 text-right">
            <button className="btn" type="button" onClick={this.handleCancel}>
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="col-lg-12 d-flex justify-content-center">
            <h3>Update {displayType} services on {this.state.vehicle.Make} {this.state.vehicle.Model}</h3>
          </div>

          <div className="col-lg-12 p-0">
            <ServiceForm
                userType={this.props.userType}
                typeId={this.props.typeId}
                currentStep={this.state.currentStep} 
                handleServices={this.handleServices}
                total={this.state.total}
                customerType= {this.state.customerType}
                vehicle={this.state.vehicle}
                discount={this.state.discount}
                services={this.state.services}
                notes = {this.state.request}
                onCancel = {this.handleCancel}/>

          </div>
          <div className="m-5 d-flex justify-content-center">
              {/* <pre>{this.state.vehicle}</pre> */}
              <div className="p-2">
                <button type="button" disabled={this.state.services.length === 0} onClick={this.handleSubmit} className="btn btn-primary m-2 buttons">UPDATE</button>
              </div>
              <div className="p-2">
              <button type="button" onClick={this.handleCancel} className="btn btn-secondary m-2 buttons">CANCEL</button>
              </div>
          </div>

        </div>
      </React.Fragment>
    );
  };

}
export default withRouter(EditJob);
