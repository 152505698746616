
import React, { Component } from 'react';
import Select from '../../common/select';
import {AddService} from '../../partial/addService';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";


import { HTTPService } from "../../../services/httpService";

const API = new HTTPService();


export default class Services extends Component {

    state = {
        catalogs : [] ,
        selectedCatalog : "Catalog",
        showCatalog : false,
        categories : [] ,
        selectedCategory : "Category",
        showCategory : false,
        selectedCategoryId : "",
        services : [] ,
        allServices : [],
        serviceValues : [],
        selectedService : "Service",
        selectedServiceId : "",
        showService : false,
        errors : {},
        message : "",
        showModal : false,
        modalAction : "",
        priceL : 0.0,
        priceM : 0.0,
        priceS : 0.0,
        }

// this will return the services offered

    async componentDidMount() {
        // populate years from current year + 1 back to 1950
        // console.log("Here's the installer catalogs we are looking for :", this.props.typeId);
        let catalogsRequest = "/catalogs/" + this.props.typeId;        
        const  { data : catalogs }  =  await API.get(catalogsRequest);
        this.setState({ catalogs , selectedCatalog : "Catalog" });  
        };

    readCatalog = async () => {
        let catalogRequest = "/catalogs/" + this.props.typeId;        
        const  { data : catalogs }  =  await API.get(catalogRequest);
        this.setState({ catalogs , selectedCatalog : "Catalog" });  
    }

    showCatalog = async () => {
        this.setState({showCatalog : true});
    };
    
    addService = async (catalog, category, description, duration, priceL, priceM, priceS) => {
        // console.log("We are adding :", catalog, category, description, duration, priceL, priceM, priceS)
        let maxServiceRequest = "/maxService/" + this.props.typeId;        
        const  { data : lastService }  =  await API.get(maxServiceRequest);
        // console.log ("Last Service number :", lastService[0].last_service);
        for (let i=1; i< 4; i++ ){

            let newService = {
                serviceId : lastService[0].last_service + i,
                serviceCategory : category,
                description : description,
                serviceCost : 0,
                durationDays : duration,
                installerId : this.props.typeId,
                installerCategory : "Paint",
                vehicleSize : "",
                dataCollect : "N",
                catalog : catalog
            };
            if (i===1) {
                newService.serviceCost = priceL;
                newService.vehicleSize = "L";
            }
            if (i===2) {
                newService.serviceCost = priceM;
                newService.vehicleSize = "M";
            }
            if (i===3) {
                newService.serviceCost = priceS;
                newService.vehicleSize = "S";
            }
            let serviceAdd = "/services";        
            const  { data : service }  =  await API.post(serviceAdd, newService);
            // console.log("Services : addService : ", service);
        }
        alert("Service added");
        this.setState({showModal : false});
        window.location = "/services";
    };

    updateService = async (catalog, category, description, duration, priceL, priceM, priceS) => {
        // console.log("We are updating :", catalog, category, description, duration, priceL, priceM, priceS)
        // console.log("and we have these services : ", this.state.serviceValues);

        // if the catalog, category, description or duration are changed, then we update all the records
        // if the prices have changed then we only need to update that price 

        for (let i=0; i< 3; i++ ){

            let newService = {
                serviceId : this.state.serviceValues[i].service_id,
                serviceCategory : this.state.serviceValues[i].service_category,
                description : this.state.serviceValues[i].description,
                serviceCost : this.state.serviceValues[i].service_cost,
                durationDays : this.state.serviceValues[i].duration_days,
                installerId : this.props.typeId,
                installerCategory : "Paint",
                vehicleSize : this.state.serviceValues[i].vehicle_size,
                dataCollect : "N",
                catalog : this.state.serviceValues[i].catalog
            };
            if (catalog) {
                newService.catalog = catalog;
            }
            if (category) {
                newService.serviceCategory = category;
            }
            if (description) {
                newService.description = description;
            }
            if (duration) {
                newService.durationDays = duration;
            }
            if (this.state.serviceValues[i].vehicle_size === "L" && priceL > 0) {
                newService.serviceCost = parseInt(priceL);
                newService.vehicleSize = "L";
            }
            if (this.state.serviceValues[i].vehicle_size === "M" && priceM > 0) {
                newService.serviceCost = parseInt(priceM);
                newService.vehicleSize = "M";
            }
            if (this.state.serviceValues[i].vehicle_size === "S" && priceS > 0) {
                newService.serviceCost = parseInt(priceS);
                newService.vehicleSize = "S";
            }

            let serviceUpdate = "/service/" + newService.serviceId + "/" + newService.installerId;        
            const  { data : service }  =  await API.put(serviceUpdate, newService);
            // console.log("Services : updateService : ", newService);
        }
        alert("Service updated");
        this.setState({showModal : false});
        window.location = "/services";

    };

    readCategories = async (passed) => {
        // console.log("Services : readCategories : ", this.state.allServices);

        // console.log("Here's the categories we are looking for :", passed);
        let categoriesRequest = "/categories/" + this.props.typeId + "/" +passed;        
        const  { data : categories }  =  await API.get(categoriesRequest);
        this.setState({ categories , selectedCategory : "Category" });  
        }

    showCategory = async () => {
        this.setState({showCategory : true});
    };

    readServices = async (catalog, category) => {
        // console.log("Services : readServices : ", this.state.allServices);

        let servicesRequest = "/categoryServices/" + this.props.typeId + "/" +catalog+"/"+category;        
        const  { data : services }  =  await API.get(servicesRequest);
        const displayServices = _.uniqBy(services, service => service.description);
        // console.log("Here are the category services * : ", services);

        this.setState({ services : displayServices, allServices : services, selectedService : "Service" });  
        }

    showService = async (mode) => {
        // make sure to set up the right variables

        this.setState({showModal : true, modalAction: mode});
    };
        
    handleCatalogChange = (event) => {
        // console.log("Services : handleCatalogChange : ", this.state.allServices);

        const { value } = event.target;      
        // When make changes, filter to show new selection
//        // console.log ("This is the catalog list :", this.state.catalogs);
//        // console.log ("This is the selected value", value);
        const dispCatalog = this.state.catalogs.filter(item => item.catalog === value);
//        // console.log ("Selected Catalog :", dispCatalog[0].catalog);
        this.readCategories(dispCatalog[0].catalog);
        this.setState( {selectedCatalog : dispCatalog[0].catalog, selectedService : "Service", services : []});
    }

    handleCategoryChange = (event) => {
        // console.log("Services : handleCategoryChange : ", this.state.allServices);
        const { value } = event.target;      
        // When category changes, read the new services list from the database
        // console.log("Selected Category: ", value)
        const dispCategory = this.state.categories.filter(category => category.service_category === value);
        this.readServices(this.state.selectedCatalog, dispCategory[0].service_category );
        this.setState( {selectedCategory : dispCategory[0].service_category});
    }
    
    handleServiceChange = (event) => {
        // console.log("Services : handleServiceChange : ", this.state.allServices);

        const { value } = event.target;      
        // console.log("Selected Service: ", value)
        const serviceValues = this.state.allServices.filter(service => service.description === value);
        // console.log ("Here are the service values", serviceValues);
        this.setState( {selectedService : value, serviceValues});

    }

    handleCancel = (event) => {
        this.setState({showModal : false})
    }

    handleSubmit = (event) => {
        // console.log ("Ready to process updates");
        this.setState({showModal : false})
    }

    render() {  
        
        // make sure to only include the catalogs that are applicable
        var catalogList = this.state.catalogs.map(a=>a.catalog);
        catalogList.unshift("Catalog");
        // make sure to only include the makes that are applicable
        var categoryList = this.state.categories.map(a=>a.service_category);
        categoryList.unshift("Category");
        // make sure to only include the makes that are applicable
        var serviceList = this.state.services.map(a=>a.description);
        serviceList.unshift("Service");
        const backgroundStyle= {backgroundColor : '#FFD9AC', height : "450px"};
        const blockHeight = {height : "200px"};
        const greyBackround = {backgroundColor : "#FFFFFF", height : "200px"};

        const columns = [{
            dataField: 'vehicle_size',
            text: "Vehicle Size"
        }, {
            dataField: 'service_cost',
            text: "Price"
        }, {
            dataField: 'duration_days',
            text: "Duration"
        } ];
        
        let services_ = _.orderBy(this.state.serviceValues,'desc','vehicle_size');
        let priceL = 0;
        let serviceL = "";
        let priceM = 0;
        let serviceM = "";
        let priceS = 0;
        let serviceS = "";
        let duration = 0;
//        // console.log("List for display : ", services_);
        for (let i=0; i < services_.length; i++) {
            services_[i].service_cost = this.state.serviceValues[i].service_cost;
            if (services_[i].vehicle_size === "L"){
                priceL = services_[i].service_cost;
                serviceL = services_[i].service_id;
            }
            if (services_[i].vehicle_size === "M"){
                priceM = services_[i].service_cost
                serviceM = services_[i].service_id;
            }
            if (services_[i].vehicle_size === "S"){
                priceS = services_[i].service_cost
                serviceS = services_[i].service_id;
            }
            duration = services_[i].duration_days;
        }
          
        // console.log("This is what we have before calling modal :" , services_);

        return (  

            <React.Fragment>
                <h4 className='text-center'>Manage Services Catalog</h4>
                <form className="bg-complete shadow  p-5 corners">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2">
                        <Select 
                            name="catalogs"
                            label=" "
                            options={catalogList}
                            value={this.state.selectedCatalog}
                            onChange={this.handleCatalogChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="categories"
                            label=" "
                            options={categoryList}
                            value={this.state.selectedCategory}
                            onChange={this.handleCategoryChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="services"
                            label=" "
                            options={serviceList}
                            value={this.state.selectedService}
                            onChange={this.handleServiceChange}
                            />
                        </div>
                    </div>
                    </form>
                    {this.state.selectedService !== "Service" && <div className="card text-center">
                        <div className="card-body">
                            <div className="table-responsive">
                                <BootstrapTable
                                    keyField='service_id'
                                    data={services_}
                                    columns={columns} />
                            </div>
                        </div>
                    </div>}

                    <div className="col text-center">
                        <button type="button" className="btn shadow btn-success m-2 buttons" onClick={() => this.showService("add")} >Add Service</button>
                        {this.state.selectedService !== "Service" && <button type="button" className="btn shadow btn-success m-2 buttons" onClick={() => this.showService("update")}>Update Service</button>}
                    </div>
                    <AddService
                        show={this.state.showModal}
                        onHide={this.handleCancel}
                        installer={this.props.typeId}
                        catalog={this.state.selectedCatalog}
                        category={this.state.selectedCategory}
                        description={this.state.selectedService}
                        duration={duration}
                        priceL={priceL}
                        serviceL={serviceL}
                        priceM={priceM}
                        serviceM={serviceM}
                        priceS={priceS}
                        serviceS={serviceS}
                        action={this.state.modalAction}
                        onAdd={this.addService}
                        onUpdate={this.updateService}
                        />
            </React.Fragment>
        );
    };

};
