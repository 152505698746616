import moment from 'moment';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { HTTPService } from "./../../../services/httpService";


const API = new HTTPService();


const rowStyle = (row) => {
    const style = {};

    // add grey in here for draft
    if (row.requestState === "draft" && row.requestType==="installer") {
        style.backgroundColor = '#A2E6C3';
    };      
    if (row.requestState === "draft" && row.requestType==="dealer") {
        style.backgroundColor = '#A0A1A2';
    };      
    if (row.requestState === "pending") {
        style.backgroundColor = '#FFD9AC';
    };      
    if (row.requestState === "booked") {
        style.backgroundColor = '#98CBE5';
    };      
    return style;
};

const addAppointment = async (appointment, description) => {
 //   alert ("Add to the calendar");

    let readAppt = `/readAppt/${appointment}`;        
    const { data: appt } = await API.get(readAppt);

//    console.log("Here's the data for the appointment :", appt);
//    console.log("description :", description);

    let newAppt = {
        request : appointment,
        summary : description,
        description : "",
        colorId : "6",
        startDate : appt[0].work_date,
        duration : appt[0].request_duration,
        token : appt[0].refresh_token
    };

    let apptAdd = `/addAppt/`;        
    const { data: addAppt } = await API.put(apptAdd, newAppt);
    alert ("Added to your google calendar");

    addAppt.then(() => window.location = "/calendar");
};

const delAppointment = async (appointment) => {

    let readAppt = `/readAppt/${appointment}`;        
    const { data: appt } = await API.get(readAppt);

  //  console.log("Here's the data for the appointment :", appt);
    
    let newAppt = {
        eventId : appt[0].event_id,
        token : appt[0].refresh_token
    };

    let apptDel = `/delAppt/`;        
    const { data: delAppt } = await API.put(apptDel, newAppt);
    window.location = "/calendar";

};


const CalendarCard = ({date, x, workTable }) => {

    // if date is today, set the card header background red
    // set the color of the rows in the tables based on the row state

    //console.log ("Calendar Card : ",  date, x, workTable);
    const matchers = workTable;
    let borderColor = "";
    if (date === new moment().format("ddd Do MMMM"))
        borderColor = "danger";

    const appts = matchers.filter(day => (day.startDateString === date));

    console.log ("Calendar card : Matching :", appts);
    return ( 
		<Card border={borderColor}>
            <Card.Header >
                    {date} ({appts.length})
            </Card.Header>
                <Card.Body>
                <div>
                {matchers.map((value,index) => ( value.startDateString === date ?
                    <div key={index}>
                        <div className="row" style={rowStyle(value)}>
                            <div className="col-9">
                            {value.workString}
                            </div>
                            <div className="col-3">
                            Due {value.dueDateString}
                            </div>
                        </div>
                    </div>
                 : null
                ))}
                
                </div>
                </Card.Body>
        </Card>
    );
} 
export default CalendarCard;